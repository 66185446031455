import {
  formatTime as commonFormatTime,
  formatLongDate as commonFormatLongDate,
  formatShortDate as commonFormatShortDate,
  formatLongMonth as commonFormatLongMonth,
  formatShortMonth as commonFormatShortMonth,
  formatRelative as commonFormatRelative,
} from '@rewardopl/utils/date';

import { locale } from '../i18n/i18n';

type Options = {
  locale?: 'de' | 'en' | 'pl' | 'uk';
};

function commonFormatShortWeekday(date?: Date | number | string, options: Options = {}) {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(options.locale, {
    weekday: 'short',
  }).format(new Date(date));
}

function commonFormatWeekday(date?: Date | number | string, options: Options = {}) {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(options.locale, {
    weekday: 'long',
  }).format(new Date(date));
}

function getOptionsWithLocale(options: Options): Options {
  const optionsWithLocale = {
    ...options,
  };

  if (locale) {
    optionsWithLocale.locale = locale;
  }

  return optionsWithLocale;
}

export function withLocale<T, U>(
  fn: (value?: T, options?: Options) => U,
): (value?: T, options?: Options) => U {
  return function withLocaleInternal(value?: T, options = {}) {
    const optionsWithLocale = getOptionsWithLocale(options);

    return fn(value, optionsWithLocale);
  };
}

export const formatTime = withLocale(commonFormatTime);
export const formatLongDate = withLocale(commonFormatLongDate);
export const formatShortDate = withLocale(commonFormatShortDate);
export const formatLongMonth = withLocale(commonFormatLongMonth);
export const formatShortMonth = withLocale(commonFormatShortMonth);
export const formatRelative = withLocale(commonFormatRelative);
export const formatShortWeekday = withLocale(commonFormatShortWeekday);
export const formatWeekday = withLocale(commonFormatWeekday);
