import { Wrapper } from './account_info.styles';

import AccountMenu from './account_menu';

export default function AccountInfo() {
  return (
    <Wrapper>
      <AccountMenu />
    </Wrapper>
  );
}
