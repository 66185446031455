import { useEffect, useState } from 'react';

function waitForDefined(variable: string): Promise<void> {
  return new Promise((resolve) => {
    function checkForDefined() {
      if (variable in window) {
        resolve();
      } else {
        setTimeout(checkForDefined, 100);
      }
    }

    checkForDefined();
  });
}

let didInit = false;

export default function OpenWidgetHandler() {
  const [didLoad, setDidLoad] = useState('OpenWidget' in window);

  useEffect(() => {
    if (didLoad) {
      return;
    }

    waitForDefined('OpenWidget').then(() => {
      setDidLoad(true);
    });
  }, [didLoad]);

  useEffect(() => {
    if (!didLoad || !OpenWidget) {
      return;
    }

    if (!didInit) {
      didInit = true;

      OpenWidget.init();
    }

    OpenWidget.call('minimize');

    return () => {
      OpenWidget.call('hide');
    };
  }, [didLoad]);

  return null;
}
