import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  gap: 10px;
`;

export const Icon = styled.span`
  width: 40px;
  height: 40px;
  overflow: hidden;
  padding: 0;
  background: var(--primary-light);
  border: 0;
  border-radius: var(--border-radius-round);
  color: var(--primary);
  text-align: center;
  line-height: 46px;
`;

export const Label = styled.span`
  ${Icon} + & {
    display: none;

    @media screen and (min-width: 769px) {
      display: inline;
    }
  }
`;
