import { Wrapper } from './header.styles';

import RewardoLogo from '../../common/rewardo_logo';
import AccountInfo from './account_info';

export default function Header() {
  return (
    <Wrapper>
      <RewardoLogo />
      <AccountInfo />
    </Wrapper>
  );
}
