import styled from 'styled-components';

export const Wrapper = styled.div`
  h1 {
    margin-bottom: var(--gap);

    @media screen and (min-width: 960px) {
      margin-bottom: calc(var(--gap) * 2);
    }
  }
`;

export const PlanAndFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    gap: 120px;
  }
`;
