import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { T, useTranslation } from '@wojtekmaj/react-t';

import { toast } from '@rewardopl/react-toast';

import LinkButton from '@rewardopl/react-ui/link_button';

import { post } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import { FormWrapper, Main, PageWrapper } from '../../login/index.styles';
import { ButtonWrapper } from '../../login/form.styles';

import LoadingText from '../../../loading_text';
import Heading from '../../../heading';
import Header from '../../../header';
import Footer from '../../../footer';

import { integrationsState } from '../../../../recoil';

import { INTEGRATIONS, INTEGRATION_TYPES } from '../../../../constants';

import type { Integration } from '@rewardopl/types';

function ContinueInApp() {
  const location = useLocation();

  return (
    <PageWrapper>
      <Header />
      <FormWrapper>
        <Main>
          <Heading align="center">Continue in app</Heading>
          <ButtonWrapper>
            <LinkButton
              as="a"
              className="primary"
              // @ts-expect-error - see https://github.com/styled-components/styled-components/issues/4112
              href={`rewardo-business://${location.pathname}${location.search}`}
            >
              <T>Continue in app</T>
            </LinkButton>
          </ButtonWrapper>
        </Main>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
}

type AuthCallbackRedirectProps = {
  queryObj: Record<string, string>;
  returnPath: string;
};

let didSend = false;

function AuthCallbackRedirect({ queryObj, returnPath }: AuthCallbackRedirectProps) {
  const { provider } = useParams();
  const navigate = useNavigate();
  const [/* integrations */ , setIntegrations] = useRecoilState(integrationsState);
  const saveErrorString = useTranslation('Failed to connect integration');

  const action = `/api/auth/${provider}/callback${stringify(queryObj)}`;

  useEffect(() => {
    if (didSend) {
      return;
    }

    didSend = true;

    (async () => {
      try {
        const response = (await post(action)) as Integration;

        setIntegrations((oldItems) => oldItems.concat(response));

        navigate(returnPath);
      } catch {
        toast.error(saveErrorString);

        navigate('/');
      } finally {
        didSend = false;
      }
    })();
  }, [action, navigate, returnPath, saveErrorString, setIntegrations]);

  return <LoadingText />;
}

export default function AuthCallback() {
  const { provider } = useParams();
  const [searchParams] = useSearchParams();

  const state = searchParams.get('state');

  const queryObj = (():
    | { token: string; cloudid: string }
    | { code: string; state: string }
    | Record<string, never>
    | null => {
    switch (provider) {
      case INTEGRATION_TYPES[INTEGRATIONS.DOTYKACKA]: {
        const token = searchParams.get('token');
        const cloudid = searchParams.get('cloudid');

        if (!token) {
          throw new Error('token is undefined');
        }

        if (!cloudid) {
          throw new Error('cloudid is undefined');
        }

        return { token, cloudid };
      }
      case INTEGRATION_TYPES[INTEGRATIONS.FOODSOFT]: {
        return {};
      }
      case INTEGRATION_TYPES[INTEGRATIONS.GOPOS]: {
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (!code) {
          throw new Error('code is undefined');
        }

        if (!state) {
          throw new Error('state is undefined');
        }

        return { code, state };
      }
      default:
        return null;
    }
  })();

  if (!queryObj) {
    return <Navigate to="/" />;
  }

  const decodedState = state
    ? (JSON.parse(decodeURIComponent(state)) as {
        mobile?: boolean;
        returnPath?: string;
      })
    : null;

  const isMobileLogin = decodedState?.mobile;
  const returnPath = decodedState?.returnPath || '/';

  if (isMobileLogin) {
    return <ContinueInApp />;
  }

  return <AuthCallbackRedirect queryObj={queryObj} returnPath={returnPath} />;
}
