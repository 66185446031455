import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  line-height: 2;
  gap: var(--gap);

  a {
    color: inherit;
  }

  button {
    min-height: 0;
    padding: 0;
    background: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    text-align: center;
  }
`;
