import styled from 'styled-components';

import { Wrapper as FieldWrapper, LabelAndChildren } from '@rewardopl/react-ui/field/index.styles';

export const Wrapper = styled.div`
  ${FieldWrapper} {
    margin-bottom: 16px;

    &:not(:first-child) {
      padding-top: 16px;
      border-top: 1px solid var(--gray);
    }
  }

  ${LabelAndChildren} {
    align-items: center;
  }
`;
