import T from '@wojtekmaj/react-t';

import { Wrapper } from './choose_plan.styles';

import PlanButtons from './plan_buttons';

type ChoosePlanProps = {
  onChange: (planId: string) => void;
  value: string;
};

export default function ChoosePlan({ onChange, value }: ChoosePlanProps) {
  return (
    <Wrapper>
      <h2>
        <T>Choose plan</T>
      </h2>
      <PlanButtons name="plan" onChange={onChange} value={value} />
    </Wrapper>
  );
}
