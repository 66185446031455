import T from '@wojtekmaj/react-t';

import { Wrapper, Button, Sub } from './begin_trial_button.styles';

export default function BeginTrialButton() {
  return (
    <Wrapper>
      <Button className="primary" type="submit">
        <T>Begin free trial</T>
        <Sub>
          <T>No credit card required</T>
        </Sub>
      </Button>
    </Wrapper>
  );
}
