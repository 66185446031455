import { useRecoilValue } from 'recoil';

import { ListItemLink } from './menu.styles';

import { currentAccountState } from '../../../recoil';

import type { NavLink } from 'react-router-dom';
import type { Account } from '@rewardopl/types';

type AuthListItemLinkProps = React.ComponentProps<typeof NavLink> & {
  anyOf: ((currentAccount: Account) => boolean)[];
};

export default function AuthListItemLink({ anyOf, ...otherProps }: AuthListItemLinkProps) {
  const currentAccount = useRecoilValue(currentAccountState);

  const isAuthorized = anyOf.some((fn) => fn(currentAccount));

  if (!isAuthorized) {
    return null;
  }

  return <ListItemLink {...otherProps} />;
}
