import T from '@wojtekmaj/react-t';

import { Section } from './index.styles';

import renderFields from '../../../form/render_fields';

import fields from '../../dashboard/settings/account/edit/fields';

import type { Business } from '@rewardopl/types';

type PaymentDetailsFormProps = {
  formData: Partial<Business>;
  setFormData: (formData: Partial<Business>) => void;
};

export default function PaymentDetailsForm({ formData, setFormData }: PaymentDetailsFormProps) {
  return (
    <Section>
      <h2>
        <T>Payment details</T>
      </h2>
      {renderFields({
        fields,
        formData,
        setFormData,
      })}
    </Section>
  );
}
