/**
 * Returns "apples, oranges and bananas" given ["apples", "oranges", "bananas"].
 *
 * @param {string[]} [list] Array of strings
 * @param {string} [joiner] Separator to use between items
 * @returns {string} Joined list
 */
export function joinedList(list: string[] = [], joiner = 'and'): string {
  if (list.length <= 1) {
    return list[0] || '';
  }

  return `${list.slice(0, -1).join(', ')} ${joiner} ${list.slice(-1)}`;
}
