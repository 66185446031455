import { Suspense } from 'react';

import { Wrapper } from './index.styles';

import LoadingText from '../../loading_text';
import Fieldset from '../fieldset';
import MapComponent from './map';

type Location = [number, number];

type LocationFieldProps = {
  label?: React.ReactNode;
  name: string;
  onChange: (value: Location) => void;
  required?: boolean;
  value?: Location;
};

const defaultValue: Location = [19.145136, 51.919438];

export default function LocationField({
  label,
  name,
  onChange,
  required = true,
  value,
}: LocationFieldProps) {
  const valueWithDefault = value || defaultValue;

  return (
    <Fieldset label={label} required={required}>
      <Wrapper>
        <Suspense fallback={<LoadingText />}>
          <MapComponent onChange={onChange} value={value} />
        </Suspense>
      </Wrapper>
      <input type="hidden" name={`${name}[0]`} value={valueWithDefault[0]} />
      <input type="hidden" name={`${name}[1]`} value={valueWithDefault[1]} />
    </Fieldset>
  );
}
