import styled from 'styled-components';

export const Wrapper = styled.footer`
  padding: var(--gap);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap);

  @media screen and (min-width: 769px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1100px) {
    flex-wrap: nowrap;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  a {
    white-space: nowrap;
  }

  @media screen and (min-width: 1100px) {
    flex-basis: 33%;
    flex-wrap: nowrap;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--gap);

  a {
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-round);
    line-height: 0;
    color: inherit;

    &:hover,
    &:focus {
      background: var(--primary-light);
    }
  }

  @media screen and (min-width: 1100px) {
    flex-basis: 33%;
  }
`;

export const Icon = styled.span`
  width: 24px;
  height: 24px;
  line-height: 0;
`;

export const Copyright = styled.p`
  margin: 0;
  text-align: center;

  @media screen and (min-width: 769px) {
    flex-grow: 1;
    width: 100%;
  }

  @media screen and (min-width: 1100px) {
    flex-basis: 33%;
    flex-grow: 0;
    text-align: right;
  }
`;
