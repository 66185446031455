import { Suspense, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { Wrapper, PlanAndFeaturesWrapper } from './index.styles';

import LoadingText from '../../../loading_text';
import ChoosePlan from './choose_plan';
import YoullReceive from './youll_receive';
import BeginTrialButton from './begin_trial_button';

import { plansState } from '../../../../recoil';

import type { Plan } from '@rewardopl/types';

const sortByMaxPlaces = (a: Plan, b: Plan) => (a.max_places || 0) - (b.max_places || 0);

export default function OnboardingPlan() {
  const navigate = useNavigate();
  const plans = useRecoilValue(plansState);

  const plansSortedByMaxPlaces = [...plans].sort(sortByMaxPlaces);

  const firstPlan = plansSortedByMaxPlaces[0];

  if (!firstPlan) {
    throw new Error('No plans found');
  }

  const [planId, setPlanId] = useState(firstPlan._id);

  function onSubmit() {
    navigate('../details', {
      state: {
        planId,
      },
    });
  }

  return (
    <Wrapper>
      <h1>
        <T>Register</T>
      </h1>
      <Suspense fallback={<LoadingText />}>
        <form onSubmit={onSubmit}>
          <PlanAndFeaturesWrapper>
            <ChoosePlan onChange={setPlanId} value={planId} />
            <YoullReceive />
          </PlanAndFeaturesWrapper>
          <BeginTrialButton />
        </form>
      </Suspense>
    </Wrapper>
  );
}
