import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import CheckboxField from '@rewardopl/react-ui/checkbox_field';
import InputField from '@rewardopl/react-ui/input_field';

import { get, post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from '../login/form.styles';

import ServiceContractLink from '../../service_contract_link';
import PrivacyPolicyLink from '../../privacy_policy_link';

import { currentAccountState, maybeCurrentBusinessState } from '../../../recoil';

import type { Account, Business } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

export default function RegisterForm() {
  const navigate = useNavigate();
  const setCurrentAccount = useSetRecoilState(currentAccountState);
  const setCurrentBusiness = useSetRecoilState(maybeCurrentBusinessState);
  const registerErrorString = useTranslation('Failed to register');
  const accountAlreadyExistsString = useTranslation('Account already exists');

  const action = '/api/businesses';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const business = await post(action, {
        body: formData,
      });

      const user = (await get('/api/accounts/current')) as Account;

      setTimeout(() => {
        setCurrentBusiness(business as Business);
      }, 0);
      setCurrentAccount(user);

      navigate('/onboarding');
    } catch (error) {
      if (error instanceof Error && error.message === 'Account already exists') {
        toast.error(accountAlreadyExistsString);
        navigate('/login', { state: { email: formData.get('email') } });
        return;
      }

      toast.error(registerErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <InputField
        autoComplete="username"
        label="Email"
        name="email"
        placeholder="email@example.com"
        type="email"
      />
      <InputField
        autoComplete="new-password"
        label="Password"
        name="password"
        placeholder="••••••••"
        type="password"
      />
      <CheckboxField
        name="terms_accepted"
        label={
          <>
            <T>I have read and agree to the</T> <ServiceContractLink /> <T>and</T>{' '}
            <PrivacyPolicyLink />.
          </>
        }
        required
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Register</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
