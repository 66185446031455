import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import T from '@wojtekmaj/react-t';

import { Label } from '@rewardopl/react-ui/field/index.styles';

import { Section } from './index.styles';
import { Plan, PlanName, Price } from './chosen_plan.styles';

import { formatPrice } from '../../../../utils/helpers';

import { planQuery } from '../../../../recoil';

import type { Plan as PlanType } from '@rewardopl/types';

function shouldUsePromoPrice(plan: PlanType): plan is PlanType & { promo_price: number } {
  return Boolean(
    plan.promo_price && plan.promo_valid_to && new Date(plan.promo_valid_to) >= new Date(),
  );
}

function getPrice(plan: PlanType): number {
  if (shouldUsePromoPrice(plan)) {
    return plan.promo_price;
  }

  return plan.price;
}

export default function ChosenPlan() {
  const location = useLocation();

  const { planId } = (location.state || {}) as { planId?: string };

  if (!planId) {
    throw new Error('planId is undefined');
  }

  const plan = useRecoilValue(planQuery(planId));

  return (
    <Section>
      <Label>
        <T>Chosen plan</T>
      </Label>
      <Plan name="plan">
        <PlanName>{plan.name}</PlanName>{' '}
        <Price>
          {formatPrice(getPrice(plan))} <T>monthly</T>
        </Price>
        <Link to="../plan">
          <T>Change</T>
        </Link>
      </Plan>
    </Section>
  );
}
