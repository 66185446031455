import RadioField from '@rewardopl/react-ui/radio_field';

import { Wrapper } from './radio_fields.styles';

import Fieldset from '../fieldset';

type RadioFieldsProps = {
  form?: string;
  label: string;
  name: string;
  options: {
    label: React.ReactNode;
    value: string;
  }[];
  onChange: (value: string) => void;
  required?: boolean;
  value: string;
};

export default function RadioFields({
  form,
  label,
  name,
  options,
  onChange,
  required = true,
  value,
}: RadioFieldsProps) {
  return (
    <Fieldset label={label} required={required}>
      <Wrapper>
        {options.map((option) => (
          <RadioField
            key={option.value}
            form={form}
            label={option.label}
            checked={value === option.value}
            name={name}
            onChange={onChange}
            required={required}
            value={option.value}
          />
        ))}
      </Wrapper>
    </Fieldset>
  );
}
