import styled from 'styled-components';

import { navigation } from '@rewardopl/styles/z-index';

import { Wrapper as RewardoLogo } from '../../common/rewardo_logo.styles';
import { Logo } from '../../common/logo.styles';

export const BusinessName = styled.p`
  text-align: center;
  margin: 0;
  margin-bottom: var(--gap);
  font-size: 16px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  grid-area: aside;
  padding: 0;
  padding-left: env(safe-area-inset-left);
  background: white;
  border-top: 1px solid #eee;
  position: relative;
  z-index: ${navigation};

  ${RewardoLogo}, ${Logo}, ${BusinessName} {
    display: none;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    flex-direction: column;
    padding: 15px;
    padding-left: max(15px, env(safe-area-inset-left));
    border-top: none;
    overflow: auto;

    ${RewardoLogo}, ${Logo}, ${BusinessName} {
      display: block;
    }

    ${RewardoLogo}, ${Logo} {
      margin: 0 auto var(--gap);
    }

    ${Logo} {
      box-shadow:
        0px 2px 2px rgba(9, 15, 27, 0.05),
        0px 5px 10px rgba(9, 15, 27, 0.1);
    }
  }
`;
