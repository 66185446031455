import styled from 'styled-components';

import { visuallyHidden } from '@rewardopl/react-ui/visually_hidden/index.styles';

import { Wrapper as FieldsetWrapper, Label as FieldsetLabel } from '../fieldset.styles';

export const Wrapper = styled.div`
  ${FieldsetWrapper} {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${FieldsetLabel} {
    ${visuallyHidden}
  }
`;

export const Label = styled.span`
  font-weight: 500;
`;

export const Value = styled.span`
  display: block;
  margin-top: calc(var(--gap) / 2);
`;
