import { PROD } from './env';

import type {
  Account,
  BirthdayBonus,
  Card,
  Category,
  Challenge,
  ChallengeReward,
  CouponAvailability,
  CouponBenefits,
  CouponUsage,
  Integration,
  LevelBenefits,
  Product,
  PromotionGroupBenefits,
  ReferralBonus,
  StampType,
  StampValue,
} from '@rewardopl/types';

export const ACCOUNT_ROLES = {
  ADMIN: 'admin',
  EMPLOYEE: 'employee',
  OWNER: 'owner',
  REWARDO_ADMIN: 'rewardo_admin',
} as const satisfies Record<string, Account['role']>;

export const ALLOW_NULL_INTEGRATION = true;

export const BIRTHDAY_BONUS_TYPES = {
  POINTS: 'points',
  REWARD: 'reward',
} as const satisfies Record<string, BirthdayBonus['type']>;

export const COUPON_AVAILABILITY_TYPES = {
  DATETIME_RANGE: 'datetime_range',
  HOURS: 'hours',
  UNLIMITED: 'unlimited',
} as const satisfies Record<string, CouponAvailability['type']>;

export const COUPON_BENEFIT_TYPES = {
  DISCOUNT_PERCENTAGE: 'discount_percentage',
  DISCOUNT_AMOUNT: 'discount_amount',
  STAMP_MULTIPLY: 'stamp_multiply',
  FREEBIE: 'freebie',
} as const satisfies Record<string, CouponBenefits['type']>;

export const COUPON_USAGE_TYPES = {
  ONCE: 'once',
  ONCE_EVERY: 'once_every',
  UNLIMITED: 'unlimited',
} as const satisfies Record<string, CouponUsage['type']>;

export const BUSINESS_CATEGORIES = {
  GASTRONOMY: 'gastronomy',
  BEAUTY_AND_WELLNESS: 'beauty_and_wellness',
  SERVICES: 'services',
  ENTERTAINMENT: 'entertainment',
  HOSPITALITY_AND_TOURISM: 'hospitality_and_tourism',
  HEALTH: 'health',
  TRANSPORTATION: 'transportation',
  FASHION: 'fashion',
  OTHER: 'other',
  ONLINE: 'online',
} as const satisfies Record<string, Category>;

export const BUSINESS_IDS = {
  BUBBLEJOY: 'f9dea343-e690-444e-8696-7dea76caaa47',
  CAFE_PRZYGODA: 'e6a7532c-5eb7-4661-b4f3-e3c95b6696fd',
  HOCKI_KLOCKI: 'e79bccb0-eabf-46cc-977a-70425329e83b',
  LODOWATO: '417dd310-75b9-4e1c-b219-159ba7565e48',
  SWEET_FACTORY_STORE: '28d2eebc-66cf-4260-b563-1c26daf92c30',
} as const;

export const CARD_TYPES = {
  POINTS: 'points',
  STAMPS_STACKED: 'stamps_stacked',
} as const satisfies Record<string, Card['type']>;

export const CARD_TYPES_LABELS = {
  [CARD_TYPES.POINTS]: 'Points',
  [CARD_TYPES.STAMPS_STACKED]: 'Stamps',
} as const satisfies Record<(typeof CARD_TYPES)[keyof typeof CARD_TYPES], string>;

export const CHALLENGE_TYPES = {
  MOST_POINTS: 'most_points',
  MOST_TRANSACTIONS: 'most_transactions',
} as const satisfies Record<string, Challenge['type']>;

export const CHALLENGE_TYPES_LABELS = {
  [CHALLENGE_TYPES.MOST_POINTS]: 'Most points',
  [CHALLENGE_TYPES.MOST_TRANSACTIONS]: 'Most transactions',
} as const satisfies Record<(typeof CHALLENGE_TYPES)[keyof typeof CHALLENGE_TYPES], string>;

export const CHALLENGE_REWARD_TYPES = {
  POINTS: 'points',
  REWARD: 'reward',
  NONE: 'none',
} as const satisfies Record<string, ChallengeReward['type']>;

export const COUNTRIES = {
  GERMANY: 'GERMANY',
  POLAND: 'POLAND',
  UK: 'UK',
} as const;

export const COUNTRY_CALLING_CODES = {
  GERMANY: '+49',
  POLAND: '+48',
  UK: '+44',
} as const satisfies Record<keyof typeof COUNTRIES, string>;

export const COUNTRY_CODES = {
  GERMANY: 'DE',
  POLAND: 'PL',
  UK: 'UK',
} as const satisfies Record<keyof typeof COUNTRIES, string>;

export const COUNTRY_NAMES = {
  GERMANY: 'Germany',
  POLAND: 'Poland',
  UK: 'United Kingdom',
} as const satisfies Record<keyof typeof COUNTRIES, string>;

const isProduction = PROD;

export const GOOGLE_MAPS_API_KEY = isProduction
  ? 'AIzaSyD4Zbjw4ZtW25fD1dva_4_MZYmKo51nKlw'
  : 'AIzaSyADtUz4xTPae1kc8BZem8e0jUBgAHmMFZw';

export const GOPOS_URL = isProduction ? 'https://crm.gopos.io' : 'https://democrm.gopos.pl';

export const LEVEL_BENEFIT_TYPES = {
  DISCOUNT_PERCENTAGE: 'discount_percentage',
  DISCOUNT_AMOUNT: 'discount_amount',
  STAMP_MULTIPLY: 'stamp_multiply',
  NONE: 'none',
} as const satisfies Record<string, LevelBenefits['type']>;

export const MOBILE_PHONE_PREFIXES = {
  GERMANY: ['15', '16', '17'],
  POLAND: ['45', '50', '51', '53', '57', '60', '66', '69', '72', '73', '78', '79', '88'],
  UK: ['71', '73', '74', '75', '76', '77', '78', '79'],
} as const satisfies Record<keyof typeof COUNTRIES, unknown>;

export const PHONE_FORMATS = {
  GERMANY: {
    LANDLINE: '## ######',
    MOBILE: '#### #######',
  },
  POLAND: {
    LANDLINE: '## ### ## ##',
    MOBILE: '### ### ###',
  },
  UK: {
    LANDLINE: '## #### ####',
    MOBILE: '#### ### ###',
  },
} as const satisfies Record<
  keyof typeof COUNTRIES,
  {
    LANDLINE: string;
    MOBILE: string;
  }
>;

export const INTEGRATIONS = {
  DOTYKACKA: 'DOTYKACKA',
  FOODSOFT: 'FOODSOFT',
  GOPOS: 'GOPOS',
} as const;

export const INTEGRATION_NAMES = {
  DOTYKACKA: 'Dotykačka',
  FOODSOFT: 'Foodsoft',
  GOPOS: 'GoPOS',
} as const satisfies Record<keyof typeof INTEGRATIONS, string>;

export const INTEGRATION_TYPES = {
  DOTYKACKA: 'dotykacka',
  FOODSOFT: 'foodsoft',
  GOPOS: 'gopos',
} as const satisfies Record<keyof typeof INTEGRATIONS, Integration['type']>;

export const ITEM_TYPES = {
  PRODUCT: 'product',
  SERVICE: 'service',
} as const satisfies Record<string, Product['type']>;

export const ITEM_TYPES_LABELS = {
  [ITEM_TYPES.PRODUCT]: 'Product',
  [ITEM_TYPES.SERVICE]: 'Service',
} as const satisfies Record<(typeof ITEM_TYPES)[keyof typeof ITEM_TYPES], string>;

export const PROMOTION_GROUP_BENEFIT_TYPES = {
  DISCOUNT_PERCENTAGE: 'discount_percentage',
  DISCOUNT_AMOUNT: 'discount_amount',
  STAMP_MULTIPLY: 'stamp_multiply',
  CUSTOM: 'custom',
  NONE: 'none',
} as const satisfies Record<string, PromotionGroupBenefits['type']>;

export const REFERRAL_BONUS_TYPES = {
  POINTS: 'points',
  REWARD: 'reward',
} as const satisfies Record<string, ReferralBonus['type']>;

export const STAMP_TYPES = {
  DEFAULT: 'default_stamp',
  // Food and drink
  MUG: 'mug',
  COFFEE_BEAN: 'coffee_bean',
  COFFEE_CUP: 'coffee_cup',
  COFFEE_POT: 'coffee_pot',
  TEA_CUP: 'tea_cup',
  SODA: 'soda',
  BEER_PINT: 'beer_pint',
  COCKTAIL: 'cocktail',
  COCKTAIL_2: 'cocktail_2',
  WINE_GLASS: 'wine_glass',
  BURGER: 'burger',
  PIZZA: 'pizza',
  HOT_DOG: 'hot_dog',
  SANDWICH: 'sandwich',
  SALAD: 'salad',
  CAKE: 'cake',
  CUPCAKE: 'cupcake',
  // Beauty and fashion
  NAIL_POLISH: 'nail_polish',
  HIGH_HEELS: 'high_heels',
  SHOES: 'shoes',
  // Services
  HAIR_DRYER: 'hair_dryer',
  COMB: 'comb',
  SCISSORS: 'scissors',
  ELECTRIC_RAZOR: 'electric_razor',
  SPONGE: 'sponge',
  BICYCLE: 'bicycle',
  CAR: 'car',
  WHEEL: 'wheel',
  HAMMER: 'hammer',
  DRILLER: 'driller',
  SEWING_MACHINE: 'sewing_machine',
  BRUSH: 'brush',
  KEY: 'key',
  PENCIL_AND_RULER: 'pencil_and_ruler',
  // Grocery
  SHOPPING_BAGS: 'shopping_bags',
  APPLE: 'apple',
  TOMATO: 'tomato',
  // Brands
  CAFE_PRZYGODA: 'cafe_przygoda',
  HOCKI_KLOCKI: 'hocki_klocki',
} as const satisfies Record<string, StampType>;

export const STAMP_TYPES_LABELS = {
  [STAMP_TYPES.DEFAULT]: 'Default',
  [STAMP_TYPES.COCKTAIL_2]: 'Cocktail (2)',
  [STAMP_TYPES.CAFE_PRZYGODA]: 'Cafe Przygoda',
  [STAMP_TYPES.HOCKI_KLOCKI]: 'Hocki Klocki',
} as const satisfies Partial<Record<(typeof STAMP_TYPES)[keyof typeof STAMP_TYPES], string>>;

export const STAMP_VALUES = {
  ABOVE: 'above',
  EACH: 'each',
  ITEM: 'item',
  VISIT: 'visit',
  CUSTOM: 'custom',
  UNDEFINED: 'undefined',
} as const satisfies Record<string, StampValue['type']>;

export const TIN_FORMATS = {
  POLAND: '###-###-##-##',
  UK: '##### #####',
} as const;

export const WEEKDAYS = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const;

export const WEEKDAY_NAMES = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
} as const satisfies Record<keyof typeof WEEKDAYS, string>;

export const WEEKDAY_ABBRS = {
  MONDAY: 'Mo',
  TUESDAY: 'Tu',
  WEDNESDAY: 'We',
  THURSDAY: 'Th',
  FRIDAY: 'Fr',
  SATURDAY: 'Sa',
  SUNDAY: 'Su',
} as const satisfies Record<keyof typeof WEEKDAYS, string>;
