import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;

  @media screen and (min-width: 960px) {
    max-width: 400px;
  }

  h2 {
    margin-bottom: 16px;
    font-size: 16px;
  }
`;

export const List = styled.ul`
  list-style: none;
  color: var(--text-light);
`;

export const ListItem = styled.li`
  padding: 0.5em 0;

  svg {
    margin: -0.5em 0.5em -0.5em 0;
    stroke: #74d884;
  }
`;

export const Annotations = styled.ul`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--gray);
  color: var(--text-light);
  list-style: none;
`;

export const AnnotationsItem = styled.li``;
