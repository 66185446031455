import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import isValidNIP from 'is-valid-nip';
import T from '@wojtekmaj/react-t';

import Button from '@rewardopl/react-ui/button';
import ButtonWrapper from '@rewardopl/react-ui/button_wrapper';

import { getOnce, patch, post } from '@rewardopl/utils/network';

import Frame from '../../../frame.styles';
import { GlobalStyle } from './index.styles';

import ChosenPlan from './chosen_plan';
import UserDetailsForm from './user_details_form';
import PaymentDetailsForm from './payment_details_form';

import { currentAccountState, currentBusinessState } from '../../../../recoil';

import type { Account, Business, Plan } from '@rewardopl/types';

export default function OnboardingDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentAccount, setCurrentAccount] = useRecoilState(currentAccountState);
  const [currentBusiness, setCurrentBusiness] = useRecoilState(currentBusinessState);
  const [userData, setUserData] = useState<Partial<Account>>({});
  const [businessData, setBusinessData] = useState<Partial<Business>>({});

  const { planId } = (location.state || {}) as { planId?: string };

  if (!planId) {
    throw new Error('planId is undefined');
  }

  const { _id: businessId } = currentBusiness;
  const { _id: userId } = currentAccount;

  const action1 = `/api/businesses/${businessId}/plan_subscriptions/`;
  const action2 = `/api/businesses/${businessId}/accounts/${userId}`;
  const action3 = `/api/businesses/${businessId}/`;

  useEffect(() => {
    if (!businessData.tin || !isValidNIP(businessData.tin)) {
      return;
    }

    (
      getOnce(`/api/business_registries/pl?nip=${businessData.tin}`) as Promise<{
        user: Partial<Account>;
        business: Partial<Business>;
      } | null>
    )
      .then((response) => {
        if (!response) {
          return;
        }

        const { user: nextUserData, business: nextBusinessData } = response;

        setUserData((prevUserData) => ({
          ...prevUserData,
          ...nextUserData,
        }));
        setBusinessData((prevBusinessData) => ({
          ...prevBusinessData,
          ...nextBusinessData,
        }));
      })
      .catch(() => {
        // Do nothing
      });
  }, [businessData.tin]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!currentBusiness) {
      throw new Error('currentBusiness is undefined');
    }

    const response1 = (await post(action1, {
      body: { plan_id: planId },
    })) as Plan;

    setCurrentBusiness({
      ...currentBusiness,
      plan_id: response1._id,
    });

    const response2 = (await patch(action2, {
      body: userData,
    })) as Account;

    setCurrentAccount(response2);

    const response3 = (await patch(action3, {
      body: businessData,
    })) as Business;

    setCurrentBusiness(response3);

    setTimeout(() => navigate('/'), 0);
  }

  return (
    <>
      <GlobalStyle />
      <form onSubmit={onSubmit}>
        <h1>
          <T>Complete the details</T>
        </h1>
        <Frame>
          <ChosenPlan />
          <PaymentDetailsForm formData={businessData} setFormData={setBusinessData} />
          <UserDetailsForm formData={userData} setFormData={setUserData} />
          <ButtonWrapper>
            <Button onClick={() => navigate(-1)} type="button">
              <T>Back</T>
            </Button>
            <Button className="primary" type="submit">
              <T>Submit</T>
            </Button>
          </ButtonWrapper>
        </Frame>
      </form>
    </>
  );
}
