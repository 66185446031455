import CheckboxField from '@rewardopl/react-ui/checkbox_field';

type CheckboxFieldWithFalsyProps = React.ComponentProps<typeof CheckboxField>;

export default function CheckboxFieldWithFalsy({
  form,
  name,
  value,
  ...otherProps
}: CheckboxFieldWithFalsyProps) {
  const booleanValue = value === 'true' || value === 'on';

  return (
    <>
      <CheckboxField form="_private" name={name} checked={booleanValue} {...otherProps} />
      <input form={form} name={name} value={value ?? ''} type="hidden" />
    </>
  );
}
