import styled from 'styled-components';

import logoPlaceholder from '../../static/logo-placeholder.svg';

const logoPlaceholderUrl = `url("${logoPlaceholder}")`;

export const Logo = styled.img`
  display: block;
  background-color: var(--white);
  background-image: ${logoPlaceholderUrl};
  background-size: 100%;
  border: 4px solid var(--white);
  border-radius: var(--border-radius-round);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  color: transparent;
`;
