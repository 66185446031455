import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from '@wojtekmaj/react-t';

import { Wrapper, BusinessName } from './nav.styles';

import RewardoLogo from '../../common/rewardo_logo';
import Logo from '../../common/logo';
import Menu from './menu';

import { maybeCurrentBusinessState } from '../../../recoil';

export default function Nav() {
  const currentBusiness = useRecoilValue(maybeCurrentBusinessState);
  const titleString = useTranslation('Company logo');

  return (
    <Wrapper>
      <RewardoLogo width={133} />
      {currentBusiness ? (
        <Link to="/settings/public_info#logo" title={titleString}>
          <Logo business={currentBusiness} size={92} />
        </Link>
      ) : null}
      {currentBusiness ? <BusinessName>{currentBusiness.name}</BusinessName> : null}
      <Menu />
    </Wrapper>
  );
}
