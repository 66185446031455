import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 640px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-light);
  border-radius: 12px;
  overflow: hidden;
`;
