import styled from 'styled-components';

export const Plan = styled.output`
  width: 100%;
  display: flex;
  margin-bottom: 1em;
  white-space: pre;

  a {
    margin-left: auto;
  }
`;

export const PlanName = styled.div`
  margin-right: 0.5em;
  font-weight: 500;
`;

export const Price = styled.div`
  margin-right: 1em;
`;
