import styled, { css } from 'styled-components';

import { aboveContent } from '@rewardopl/styles/z-index';

import { supportsStyleChangeOnHover } from '../utils/feature_detection';

export default styled.div<{
  to?: string;
  onClick?: () => void;
}>`
  width: calc(100% + var(--gap) * 2);
  display: flex;
  flex-direction: column;
  margin: 0 calc(var(--gap) * -1);
  padding: var(--gap);
  background: white;
  border-radius: var(--border-radius);
  color: inherit;
  text-align: left;
  overflow: auto;
  transition:
    box-shadow ease-in-out 200ms,
    transform ease-in-out 200ms;

  ${(props) =>
    (props.to || props.onClick) &&
    css`
      box-shadow: var(--shadow-1);
      cursor: pointer;

      ${
        supportsStyleChangeOnHover &&
        css`
        &:hover,
        &:focus {
          text-decoration: none;
          box-shadow: var(--shadow-3);
          transform: translateY(-3px);
          z-index: ${aboveContent};
        }
      `
      }
    `}

  @media screen and (min-width: 769px) {
    width: 100%;
    margin: 0;
  }
`;
