import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25vh 25vw;
  background: var(--white);
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 192px;
  max-height: 192px;
`;
