import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import ButtonLink from '@rewardopl/react-ui/button_link';

import { post } from '@rewardopl/utils/network';

import { maybeCurrentAccountState, maybeCurrentBusinessState } from '../recoil';

export default function LogoutButton() {
  const navigate = useNavigate();
  const [currentAccount, setCurrentAccount] = useRecoilState(maybeCurrentAccountState);
  const setCurrentBusiness = useSetRecoilState(maybeCurrentBusinessState);

  async function logout() {
    await post('/api/accounts/logout');

    setCurrentAccount(null);
    setCurrentBusiness(null);

    setTimeout(() => navigate('/login'), 0);
  }

  if (!currentAccount) {
    return null;
  }

  return (
    <ButtonLink onClick={logout}>
      <T>Log out</T>
    </ButtonLink>
  );
}
