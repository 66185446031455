import { useRecoilValue } from 'recoil';

import RadioField from '@rewardopl/react-ui/radio_field';

import { Name, Sub } from './option.styles';

import ErrorBoundary from '../../../error_boundary';

import { integrationBusinessQuery } from '../../../../recoil';

import { INTEGRATIONS, INTEGRATION_NAMES, INTEGRATION_TYPES } from '../../../../constants';

type OptionContentProps = {
  checked: boolean;
  integrationBusinessName?: string;
  integrationId: string;
  integrationType: (typeof INTEGRATION_TYPES)[keyof typeof INTEGRATION_TYPES];
  onChange: (integrationId: string) => void;
};

function OptionContent({
  checked,
  integrationBusinessName,
  integrationId,
  integrationType: type,
  onChange,
}: OptionContentProps) {
  const integrationType = (Object.keys(INTEGRATIONS) as (keyof typeof INTEGRATIONS)[]).find(
    (key) => INTEGRATION_TYPES[key] === type,
  );

  if (!integrationType) {
    throw new Error(`Invalid integration type: ${type}`);
  }

  const integrationName = INTEGRATION_NAMES[integrationType];

  return (
    <RadioField
      checked={checked}
      label={
        <>
          <Name>{integrationBusinessName || <em>{integrationId}</em>}</Name>
          <Sub>{integrationName}</Sub>
        </>
      }
      name="integration"
      onChange={onChange}
      value={integrationId}
    />
  );
}

type OptionWrapperProps = {
  checked: boolean;
  integrationId: string;
  integrationType: (typeof INTEGRATION_TYPES)[keyof typeof INTEGRATION_TYPES];
  onChange: (integrationId: string) => void;
};

function OptionWrapper(props: OptionWrapperProps) {
  const { integrationId } = props;
  const integrationBusiness = useRecoilValue(integrationBusinessQuery(integrationId));

  return <OptionContent {...props} integrationBusinessName={integrationBusiness?.name} />;
}

function OptionFallbackWrapper(props: OptionWrapperProps) {
  return <OptionContent {...props} />;
}

export default function Option(props: OptionWrapperProps) {
  return (
    <ErrorBoundary fallback={<OptionFallbackWrapper {...props} />}>
      <OptionWrapper {...props} />
    </ErrorBoundary>
  );
}
