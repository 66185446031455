import { Logo as LogoElement } from './logo.styles';

import logoPlaceholder from '../../static/logo-placeholder.svg';

import type { Business } from '@rewardopl/types';

type LogoProps = {
  business: Business | null;
  size?: number;
};

export default function Logo({ business, size = 50 }: LogoProps) {
  function onError(
    event: React.SyntheticEvent<HTMLImageElement, Event> & { target: HTMLImageElement },
  ) {
    const { target } = event;

    target.src = logoPlaceholder;
  }

  return (
    <LogoElement
      alt={business?.name}
      width={size}
      height={size}
      onError={onError}
      src={business?.logo ? `/api/uploads/${business.logo}` : logoPlaceholder}
    />
  );
}
