import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import InputField from '@rewardopl/react-ui/input_field';

import { get, post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from './form.styles';

import { currentAccountState, maybeCurrentBusinessState } from '../../../recoil';

import type { Account, Business } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

export default function LoginForm() {
  const setCurrentAccount = useSetRecoilState(currentAccountState);
  const setCurrentBusiness = useSetRecoilState(maybeCurrentBusinessState);
  const loginErrorString = useTranslation('Failed to log in');
  const invalidUsernameOrPasswordString = useTranslation('Invalid username or password');

  const action = '/api/accounts/login';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    try {
      const user = (await post(action, {
        body: new FormData(form),
      })) as Account;

      if ('business_id' in user && user.business_id) {
        const business = (await get('/api/businesses/current')) as Business;

        setTimeout(() => {
          setCurrentBusiness(business);
        }, 0);
      }
      setCurrentAccount(user);
    } catch (error) {
      if (error instanceof Error && error.message === 'Invalid username or password') {
        toast.error(invalidUsernameOrPasswordString);
        return;
      }

      toast.error(loginErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <InputField
        autoComplete="username"
        label="Email"
        name="email"
        placeholder="email@example.com"
        type="email"
      />
      <InputField
        autoComplete="current-password"
        label="Password"
        name="password"
        placeholder="••••••••"
        type="password"
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Login</T>
        </AsyncButton>
      </ButtonWrapper>
      <Link to="/password_reset">
        <T>Forgot password?</T>
      </Link>
    </Form>
  );
}
