import { useRecoilValue } from 'recoil';
import { Navigate } from 'react-router-dom';

import { Wrapper } from './index.styles';

import Nav from './nav';
import Content from './content';
import WebsocketHandler from './websocket_handler';

import { maybeCurrentBusinessState } from '../../../recoil';

export default function DashboardPage() {
  const currentBusiness = useRecoilValue(maybeCurrentBusinessState);

  const hasPlan = !currentBusiness || Boolean(currentBusiness.plan_id);

  if (!hasPlan) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <Wrapper>
      <Nav />
      <Content />
      <WebsocketHandler />
    </Wrapper>
  );
}
