import { useState } from 'react';

import InputField from '@rewardopl/react-ui/input_field';
import SelectField from '@rewardopl/react-ui/select_field';

import Fieldset from './fieldset';

import { COUNTRIES, COUNTRY_CODES, COUNTRY_NAMES } from '../../constants';

import type { Address } from '@rewardopl/types/helpers/address';

type AddressFieldProps = {
  label?: React.ReactNode;
  name: string;
  onChange: (value: Partial<Address> | undefined) => void;
  required?: boolean;
  value?: Partial<Address>;
};

const countryOptions: { label: string; value: string }[] = (
  Object.keys(COUNTRIES) as (keyof typeof COUNTRIES)[]
).map((key) => ({
  label: COUNTRY_NAMES[key],
  value: COUNTRY_CODES[key],
}));

export default function AddressField({
  label,
  name,
  onChange,
  required = true,
  value,
}: AddressFieldProps) {
  const [address, setAddress] = useState(value?.address);
  const [postcode, setPostcode] = useState(value?.postcode);
  const [city, setCity] = useState(value?.city);
  const [country, setCountry] = useState(value?.country ?? 'PL');

  function onChangeAddress(nextAddress: string | undefined) {
    setAddress(nextAddress);

    onChange({
      address: nextAddress,
      postcode,
      city,
      country,
    });
  }

  function onChangePostcode(nextPostcode: string | undefined) {
    setPostcode(nextPostcode);

    onChange({
      address,
      postcode: nextPostcode,
      city,
      country,
    });
  }

  function onChangeCity(nextCity: string | undefined) {
    setCity(nextCity);

    onChange({
      address,
      postcode,
      city: nextCity,
      country,
    });
  }

  function onChangeCountry(nextCountry: string) {
    setCountry(nextCountry);

    onChange({
      address,
      postcode,
      city,
      country: nextCountry,
    });
  }

  return (
    <Fieldset label={label} required={required}>
      <InputField
        autoComplete="street-address"
        hideOptional
        label="Street address"
        name={`${name}[address]`}
        onChange={onChangeAddress}
        value={address}
        required={required}
      />
      <InputField
        autoComplete="postal-code"
        hideOptional
        label="Postcode"
        name={`${name}[postcode]`}
        onChange={onChangePostcode}
        required={required}
        value={postcode}
      />
      <InputField
        autoComplete="address-level2"
        hideOptional
        label="City"
        name={`${name}[city]`}
        onChange={onChangeCity}
        required={required}
        value={city}
      />
      <SelectField
        autoComplete="country"
        hideOptional
        label="Country"
        name={`${name}[country]`}
        onChange={onChangeCountry}
        options={countryOptions}
        required={required}
        value={country}
      />
    </Fieldset>
  );
}
