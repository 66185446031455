import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { TProvider } from '@wojtekmaj/react-t';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { registerSW } from 'virtual:pwa-register';

import Root from './components/root';
import I18nProvider from './i18n/i18n_provider';

import { languageFiles } from './i18n/i18n';

import './polyfill';

import { PROD, MODE } from './env';

import type { ShouldForwardProp } from 'styled-components';

const isProduction = PROD;

registerSW({
  immediate: true,
  onRegistered(registration) {
    if (registration) {
      setInterval(
        () => {
          registration.update();
        },
        1000 * 60, // 1 minute
      );
    }
  },
});

if (isProduction) {
  Sentry.init({
    dsn: 'https://3db606d2cf7a4eecbfaa7a96df80203f@o460867.ingest.sentry.io/5502062',
    environment: MODE,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = document.getElementById('root');

if (!root) {
  throw new Error('Could not find root element');
}

const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }

  // For other elements, forward all props
  return true;
};

createRoot(root).render(
  <StrictMode>
    <RecoilRoot>
      <Router>
        <HelmetProvider>
          <TProvider languageFiles={languageFiles}>
            <I18nProvider>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <Root />
              </StyleSheetManager>
            </I18nProvider>
          </TProvider>
        </HelmetProvider>
      </Router>
    </RecoilRoot>
  </StrictMode>,
);
