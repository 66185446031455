import styled from 'styled-components';

export const Form = styled.form``;

export const Name = styled.p`
  margin: -1.5px 0 0;
  font-size: 16px;
  font-weight: 500;
`;

export const Sub = styled.p`
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 12px;
`;
