import InputField from '@rewardopl/react-ui/input_field';
import SelectField from '@rewardopl/react-ui/select_field';
import TextareaField from '@rewardopl/react-ui/textarea_field';

import { toNaturalSentence } from '@rewardopl/utils/string';

import AddressField from './address_field';
import AddressFieldWithDefault from './address_field_with_default';
import CheckboxFieldWithFalsy from './checkbox_field_with_falsy';
import LocationField from './location_field';

import type { FieldsOrFieldsGetter } from '../../types';

type RenderFieldsArgs<T extends object> = {
  fields: FieldsOrFieldsGetter<T>;
  formData: Partial<T>;
  setFormData: (data: Partial<T>) => void;
};

export default function renderFields<T extends object>({
  fields,
  formData,
  setFormData,
}: RenderFieldsArgs<T>) {
  const resolvedFields = typeof fields === 'function' ? fields({ formData }) : fields;
  const filteredFields = resolvedFields.filter(Boolean);

  return filteredFields.map((field) => {
    const props = {
      key: (field.form || '') + field.name,
      label: field.label || toNaturalSentence(field.name),
      // biome-ignore lint/suspicious/noExplicitAny: Too complex to type
      onChange: (value: any) => setFormData({ ...formData, [field.name]: value }),
      value:
        formData[field.name as keyof T] ??
        // biome-ignore lint/suspicious/noExplicitAny: Too complex to type
        (('defaultValue' in field ? field.defaultValue : undefined) as any),
    };

    switch (field.type) {
      case 'address': {
        return <AddressField {...field} {...props} />;
      }
      case 'address_with_default': {
        return <AddressFieldWithDefault {...field} {...props} />;
      }
      case 'checkbox': {
        const { value, ...otherProps } = props;

        return <CheckboxFieldWithFalsy {...field} {...otherProps} value={value?.toString()} />;
      }
      case 'custom': {
        const { customField: CustomField } = field;

        return <CustomField {...field} {...props} />;
      }
      case 'hidden': {
        const { defaultValue, ...fieldWithoutDefaultValue } = field;
        const { label, ...otherProps } = props;

        return <input {...fieldWithoutDefaultValue} {...otherProps} />;
      }
      case 'location': {
        return <LocationField {...field} {...props} />;
      }
      case 'select': {
        return <SelectField {...field} {...props} />;
      }
      case 'textarea': {
        return <TextareaField {...field} {...props} />;
      }
      case 'text':
      default: {
        return <InputField {...field} {...props} />;
      }
    }
  });
}
