import { Suspense, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import T, { useTranslation } from '@wojtekmaj/react-t';

import Button from '@rewardopl/react-ui/button';
import Dialog from '@rewardopl/react-ui/dialog';
import RadioField from '@rewardopl/react-ui/radio_field';

import { Wrapper, Form, List, ButtonWrapper } from './dialog.styles';
import { Name } from './option.styles';

import LoadingText from '../../../loading_text';
import Option from './option';

import {
  currentIntegrationIdState,
  integrationsState,
  preferredIntegrationIdState,
} from '../../../../recoil';

import { ALLOW_NULL_INTEGRATION } from '../../../../constants';

type ContentProps = {
  onDismiss?: () => void;
};

function Content({ onDismiss }: ContentProps) {
  const integrations = useRecoilValue(integrationsState);
  const currentIntegrationId = useRecoilValue(currentIntegrationIdState);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | null | undefined>(
    currentIntegrationId,
  );
  const setPreferredIntegrationId = useSetRecoilState(preferredIntegrationIdState);

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setPreferredIntegrationId(selectedIntegrationId);

    if (onDismiss) {
      onDismiss();
    }
  }

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <p>
          <T>
            {ALLOW_NULL_INTEGRATION
              ? 'Please select an option to continue.'
              : 'You have multiple integrations connected to your account. Please select one to continue.'}
          </T>
        </p>
        <List>
          {integrations.map((integration) => (
            <Option
              key={integration._id}
              checked={selectedIntegrationId === integration._id}
              integrationId={integration._id}
              integrationType={integration.type}
              onChange={setSelectedIntegrationId}
            />
          ))}
          {ALLOW_NULL_INTEGRATION ? (
            <RadioField
              checked={selectedIntegrationId === null}
              label={
                <Name>
                  <T>None</T>
                </Name>
              }
              name="integration"
              onChange={() => setSelectedIntegrationId(null)}
              value="null"
            />
          ) : null}
        </List>
        <ButtonWrapper>
          <Button disabled={selectedIntegrationId === undefined} type="submit" className="primary">
            <T>Save</T>
          </Button>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
}

type SelectIntegrationDialogProps = {
  mustDisplayDialog: boolean;
  onDismiss?: () => void;
};

export default function SelectIntegrationDialog({
  mustDisplayDialog,
  onDismiss,
}: SelectIntegrationDialogProps) {
  const titleString = useTranslation('Select integration');

  return (
    <Dialog
      flexContent
      isOpen={location.hash === '#select-integration' || mustDisplayDialog}
      onDismiss={onDismiss}
      title={titleString}
    >
      <Suspense fallback={<LoadingText />}>
        <Content onDismiss={onDismiss} />
      </Suspense>
    </Dialog>
  );
}
