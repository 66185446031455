import styled from 'styled-components';

import { Wrapper as HeaderWrapper } from './header.styles';
import { Main } from './content.styles';

export const Wrapper = styled.div`
  align-self: stretch;
  background: var(--error-light);
  padding: 15px;
  font-size: 16px;
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: inherit;
    font-weight: 500;
  }

  @media screen and (min-width: 769px) {
    ${HeaderWrapper} + & {
      border-top-left-radius: var(--border-radius);
    }

    + ${Main} {
      border-top-left-radius: 0;
    }
  }
`;
