import type { FieldsOrFieldsGetter } from '../../../../../../types';
import type { Business } from '@rewardopl/types';

const fields: FieldsOrFieldsGetter<Business> = [
  { name: 'tin' },
  {
    name: 'billing_name',
    label: 'Business name',
  },
  {
    name: 'billing_address',
    type: 'address',
  },
];

export default fields;
