import { FacebookIcon, LinkedinIcon, InstagramIcon } from 'lucide-react';

import { Wrapper, Links, SocialMedia, Icon, Copyright } from './footer.styles';

import ServiceContractLink from './service_contract_link';
import PrivacyPolicyLink from './privacy_policy_link';
import ContactUsLink from './contact_us_link';

const items = [
  {
    label: 'Facebook',
    icon: FacebookIcon,
    href: 'https://www.facebook.com/RewardoLoyaltyApp',
  },
  {
    label: 'LinkedIn',
    icon: LinkedinIcon,
    href: 'https://www.linkedin.com/company/rewardo-loyalty-app/',
  },
  {
    label: 'Instagram',
    icon: InstagramIcon,
    href: 'https://www.instagram.com/rewardo_loyalty/',
  },
];

const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <Wrapper>
      <Links>
        <ServiceContractLink />
        <PrivacyPolicyLink />
        <ContactUsLink />
      </Links>
      <SocialMedia>
        {items.map((item) => {
          const IconComponent = item.icon;

          return (
            <a
              key={item.label}
              href={item.href}
              title={item.label}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon>
                <IconComponent />
              </Icon>
            </a>
          );
        })}
      </SocialMedia>
      <Copyright>{`© Rewardo sp. z o.o. 2020–${currentYear}`}</Copyright>
    </Wrapper>
  );
}
