import { useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { MenuTrigger, Button, Popover, Menu, MenuItem } from './menu_button.styles';

import type { NavigateOptions } from 'react-router-dom';
import type { Key } from 'react-aria-components';

type MenuButtonProps = {
  items: ({
    label: string;
  } & (
    | ({
        to: string;
      } & NavigateOptions)
    | {
        onSelect: () => void;
      }
  ))[];
  label: React.ReactNode;
};

export default function MenuButton({ items, label }: MenuButtonProps) {
  const navigate = useNavigate();

  function onAction(index: Key) {
    const item = items[Number(index)];

    if (!item) {
      throw new Error('Item not found');
    }

    if ('to' in item) {
      const { to, replace, state } = item;

      navigate(to, { replace, state });
      return;
    }

    item.onSelect();
  }

  return (
    <MenuTrigger>
      <Button>{label}</Button>
      <Popover>
        <Menu onAction={onAction}>
          {items.map((item, index) => {
            const { label: itemLabel } = item;

            return (
              <MenuItem
                // biome-ignore lint/suspicious/noArrayIndexKey: index is stable here
                key={index}
                id={index}
              >
                <T>{itemLabel}</T>
              </MenuItem>
            );
          })}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
