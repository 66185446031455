import T, { useTranslation } from '@wojtekmaj/react-t';

import { CheckIcon } from 'lucide-react';

import { Wrapper, List, ListItem, Annotations, AnnotationsItem } from './youll_receive.styles';

import { joinedList } from '../../../../utils/string';

import { INTEGRATION_NAMES } from '../../../../constants';

export default function YoullReceive() {
  const joiner = useTranslation('and');
  const integrations = joinedList(Object.values(INTEGRATION_NAMES), joiner);

  return (
    <Wrapper>
      <h2>
        <T>You’ll receive</T>
      </h2>
      <List role="list">
        <ListItem>
          <CheckIcon />
          <T>14-day trial with no strings attached</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>No upfront fee</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>Digital loyalty card</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>Customizable company profile and logo</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>Admin panel</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>Customer service assistant</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>Statistics and reports</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>Messages and notifications</T>
        </ListItem>
        <ListItem>
          <CheckIcon />
          <T>POS integration</T>*
        </ListItem>
      </List>
      <Annotations role="list">
        <AnnotationsItem id="ann_1">
          *{' '}
          <T integrations={integrations}>
            {'Integration with {integrations} available. More providers coming soon.'}
          </T>
        </AnnotationsItem>
      </Annotations>
    </Wrapper>
  );
}
