import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { Wrapper } from './configure_banner.styles';

import useIsConfigured from '../../../hooks/useIsConfigured';

function ConfigureBannerContent() {
  const isConfigured = useIsConfigured();

  if (isConfigured) {
    return null;
  }

  return (
    <Wrapper>
      <p>
        <T>To enjoy all the benefits</T>
      </p>
      <Link to="/configure">
        <T>Finish configuration</T>
      </Link>
    </Wrapper>
  );
}

export default function ConfigureBanner() {
  return (
    <Suspense fallback={null}>
      <ConfigureBannerContent />
    </Suspense>
  );
}
