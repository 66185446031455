import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { getAccountName } from '@rewardopl/utils/account';

import { UserIcon } from 'lucide-react';

import { post } from '@rewardopl/utils/network';

import { Wrapper, Icon, Label } from './account_menu.styles';

import MenuButton from '../../common/menu_button';

import { isRewardoAdmin } from '../../../helpers/authorization';

import {
  currentAccountState,
  maybeCurrentBusinessState,
  integrationsState,
  maybeCurrentAccountState,
} from '../../../recoil';

import { ALLOW_NULL_INTEGRATION } from '../../../constants';

export default function AccountMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentAccount = useRecoilValue(currentAccountState);
  const setMaybeCurrentAccount = useSetRecoilState(maybeCurrentAccountState);
  const integrations = useRecoilValue(integrationsState);
  const setCurrentBusiness = useSetRecoilState(maybeCurrentBusinessState);

  const hasAtLeastOneIntegration = !isRewardoAdmin(currentAccount) && integrations.length >= 1;
  const hasMultipleIntegrations = !isRewardoAdmin(currentAccount) && integrations.length > 1;

  const shouldDisplayItem = ALLOW_NULL_INTEGRATION
    ? hasAtLeastOneIntegration
    : hasMultipleIntegrations;

  async function logout() {
    await post('/api/accounts/logout');

    setMaybeCurrentAccount(null);
    setCurrentBusiness(null);

    setTimeout(() => navigate('/login'), 0);
  }

  return (
    <MenuButton
      label={
        <Wrapper>
          <Icon>
            <UserIcon size={18} />
          </Icon>
          <Label>
            <T>{getAccountName(currentAccount)}</T>
          </Label>
        </Wrapper>
      }
      items={[
        shouldDisplayItem
          ? {
              label: 'Select integration',
              to: '#select-integration',
              state: location.state,
            }
          : null,
        {
          label: 'Settings',
          to: '/settings',
        },
        {
          label: 'Log out',
          onSelect: logout,
        },
      ].filter(Boolean)}
    />
  );
}
