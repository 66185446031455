import { lazy, Suspense, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Wrapper, Main } from './content.styles';

import PageWrapper from '../../page_wrapper.styles';

import AuthGate from '../../auth_gate';
import LoadingText from '../../loading_text';

import ConfigureBanner from './configure_banner';
import SelectIntegration from './select_integration';
import Title from '../../title';
import ScrollToTop from '../../scroll_to_top';
import Header from './header';
import Footer from '../../footer';
import PlanSubscriptionIssuesBanner from './plan_subscription_issues_banner';
import OpenWidgetHandler from './openwidget_handler';

import { isAnyAdmin, isRewardoAdmin } from '../../../helpers/authorization';

const AccountsPage = lazy(() => import('./accounts'));
const BusinessesPage = lazy(() => import('./businesses'));
const CardsPage = lazy(() => import('./cards'));
const CardSubscriptionsPage = lazy(() => import('./card_subscriptions'));
const DevicesPage = lazy(() => import('./devices'));
const PlansPage = lazy(() => import('./plans'));
const HomePage = lazy(() => import('./home'));
const PlacesPage = lazy(() => import('./places'));
const ProductsPage = lazy(() => import('./products'));
const SettingsPage = lazy(() => import('./settings'));
const StatsPage = lazy(() => import('./stats'));
const TransactionsPage = lazy(() => import('./transactions'));
const UsersPage = lazy(() => import('./users'));
const ConfigurePage = lazy(() => import('./configure'));
const ScannerPage = lazy(() => import('./scanner'));

export default function Content() {
  const wrapper = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapper}>
      <Title />
      <ScrollToTop wrapper={wrapper} />
      <Header />
      <PlanSubscriptionIssuesBanner />
      <Routes>
        <Route path="" element={<ConfigureBanner />} />
      </Routes>
      <Routes>
        <Route path="scanner" element={null} />
        <Route path="*" element={<OpenWidgetHandler />} />
      </Routes>
      <SelectIntegration />
      <Main>
        <Suspense fallback={<LoadingText />}>
          <PageWrapper>
            <Routes>
              <Route path="" element={<HomePage />} />
              <Route path="accounts/*" element={<AccountsPage />} />
              <Route path="cards/*" element={<CardsPage />} />
              <Route path="card_subscriptions/*" element={<CardSubscriptionsPage />} />
              <Route path="configure/*" element={<ConfigurePage />} />
              <Route path="devices/*" element={<DevicesPage />} />
              <Route path="places/*" element={<PlacesPage />} />
              <Route path="products/*" element={<ProductsPage />} />
              <Route path="scanner/*" element={<ScannerPage />} />
              <Route path="settings/*" element={<SettingsPage />} />
              <Route
                path="stats/*"
                element={
                  <AuthGate anyOf={isAnyAdmin}>
                    <StatsPage />
                  </AuthGate>
                }
              />
              <Route path="transactions/*" element={<TransactionsPage />} />
              <Route
                path="businesses/*"
                element={
                  <AuthGate anyOf={[isRewardoAdmin]}>
                    <BusinessesPage />
                  </AuthGate>
                }
              />
              <Route
                path="plans/*"
                element={
                  <AuthGate anyOf={[isRewardoAdmin]}>
                    <PlansPage />
                  </AuthGate>
                }
              />
              <Route
                path="users/*"
                element={
                  <AuthGate anyOf={[isRewardoAdmin]}>
                    <UsersPage />
                  </AuthGate>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </PageWrapper>
        </Suspense>
      </Main>
      <Footer />
    </Wrapper>
  );
}
