import { maybeCurrentBusinessIdState } from '../../recoil';

import type { GetRecoilValue } from 'recoil';

export default function defaultGetAction({
  get,
  keys,
  scopedRoute,
}: {
  get: GetRecoilValue;
  keys: string;
  scopedRoute?: boolean;
}) {
  const currentBusinessId = get(maybeCurrentBusinessIdState);

  return `/api/${scopedRoute && currentBusinessId ? `businesses/${currentBusinessId}/` : ''}${keys}`;
}
