import { DefaultValue, atom, atomFamily, selector, selectorFamily } from 'recoil';
import { startOfDay } from 'date-fns';
import * as Sentry from '@sentry/react';

import { get as networkGet, post } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import { createSimpleState, createState } from './utils/recoil';

import { ALLOW_NULL_INTEGRATION } from './constants';

import { PROD } from './env';

import type { AtomEffect } from 'recoil';
import type { Recipient, Stats, StatsSummary, StatStyle, StatType } from './types';
import type {
  Account,
  Business,
  Card,
  CardSubscription,
  Challenge,
  Coupon,
  CouponWithStats,
  Device,
  Feedback,
  Flags,
  Integration,
  IntegrationBusiness,
  IntegrationOrder,
  Level,
  Message,
  Offer,
  Place,
  Plan,
  PlanSubscription,
  Product,
  PromotionGroup,
  Reward,
  Transaction,
  User,
} from '@rewardopl/types';

const isProduction = PROD;

function localStorageEffect<T>(key: string): AtomEffect<T | null> {
  return ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue !== null) {
      const parsedSavedValue = JSON.parse(savedValue) as T | null;

      setSelf(parsedSavedValue);

      if (key === 'account') {
        if (parsedSavedValue) {
          if (isProduction) {
            Sentry.setUser({
              id: (parsedSavedValue as T as Account)._id,
              email: (parsedSavedValue as T as Account).email,
            });
          }
        } else {
          if (isProduction) {
            Sentry.setUser(null);
          }
        }
      }
    }

    onSet((newValue) => {
      if (newValue !== undefined) {
        localStorage.setItem(key, JSON.stringify(newValue));
      } else {
        localStorage.removeItem(key);
      }
    });
  };
}

const sentryEffect: AtomEffect<Account | null> = ({ onSet }) => {
  onSet((newValue) => {
    if (newValue) {
      if (isProduction) {
        Sentry.setUser({
          id: newValue._id,
          email: newValue.email,
        });
      }
    } else {
      if (isProduction) {
        Sentry.setUser(null);
      }
    }
  });
};

const defaultMaybeCurrentAccountState = selector<Account | null>({
  key: 'maybeCurrentAccountState/default',
  async get() {
    try {
      const response = (await networkGet('/api/accounts/current')) as Account;

      localStorage.setItem('account', JSON.stringify(response));

      if (isProduction) {
        Sentry.setUser({
          id: response._id,
          email: response.email,
        });
      }

      return response;
    } catch {
      localStorage.removeItem('user');

      if (isProduction) {
        Sentry.setUser(null);
      }

      return null;
    }
  },
});

export const maybeCurrentAccountState = atom<Account | null>({
  key: 'maybeCurrentAccountState',
  default: defaultMaybeCurrentAccountState,
  effects: [localStorageEffect('account'), sentryEffect],
});

export const currentAccountState = selector<Account>({
  key: 'currentAccountState',
  get: ({ get }) => {
    const maybeCurrentAccount = get(maybeCurrentAccountState);

    if (!maybeCurrentAccount) {
      throw new Error('currentUser is required');
    }

    return maybeCurrentAccount;
  },
  set: ({ set }, newValue) => {
    set(maybeCurrentAccountState, newValue);
  },
});

export const currentAccountIdState = selector<string>({
  key: 'currentAccountIdState',
  get: ({ get }) => {
    const currentAccount = get(currentAccountState);

    return currentAccount._id;
  },
});

const defaultMaybeCurrentBusinessState = selector<Business | null>({
  key: 'maybeCurrentBusinessState/default',
  async get() {
    const cachedResponse = localStorage.getItem('business');
    if (cachedResponse) {
      // Request current business anyway to update the cache
      networkGet('/api/businesses/current')
        .then((response) => {
          localStorage.setItem('business', JSON.stringify(response));
        })
        .catch(() => {
          localStorage.removeItem('business');
        });
      return JSON.parse(cachedResponse) as Business;
    }

    let response: Business | null;
    try {
      response = await (networkGet('/api/businesses/current') as Promise<Business>);
    } catch {
      response = null;
    }

    return response;
  },
});

export const maybeCurrentBusinessState = atom<Business | null>({
  key: 'maybeCurrentBusinessState',
  default: defaultMaybeCurrentBusinessState,
  effects: [localStorageEffect('business')],
});

export const currentBusinessState = selector<Business>({
  key: 'currentBusinessState',
  get: ({ get }) => {
    const maybeCurrentBusiness = get(maybeCurrentBusinessState);

    if (!maybeCurrentBusiness) {
      throw new Error('currentBusiness is required');
    }

    return maybeCurrentBusiness;
  },
  set: ({ set }, newValue) => {
    set(maybeCurrentBusinessState, newValue);
  },
});

export const maybeCurrentBusinessIdState = selector<string | null>({
  key: 'maybeCurrentBusinessIdState',
  get: ({ get }) => {
    const currentBusiness = get(maybeCurrentBusinessState);

    return currentBusiness?._id ?? null;
  },
});

export const currentBusinessFlagsState = selector<Flags | null>({
  key: 'currentBusinessFlagsState',
  get: ({ get }) => {
    const currentBusiness = get(maybeCurrentBusinessState);

    return currentBusiness?.flags ?? null;
  },
});

const defaultCurrentPlanSubscriptionState = selector<PlanSubscription | null>({
  key: 'currentPlanSubscriptionState/default',
  async get({ get }) {
    const business = get(maybeCurrentBusinessState);

    if (!business) {
      return null;
    }

    let response: PlanSubscription | null;
    try {
      response = (await networkGet(
        `/api/businesses/${business._id}/plan_subscriptions/current`,
      )) as PlanSubscription;
    } catch {
      response = null;
    }

    return response;
  },
});

export const currentPlanSubscriptionState = atom<PlanSubscription | null>({
  key: 'currentPlanSubscriptionState',
  default: defaultCurrentPlanSubscriptionState,
});

const asyncDefaultMessagesCount = selectorFamily<number, string>({
  key: 'messagesCount/default',
  get: (businessId) => async () => {
    const response = (await networkGet(`/api/businesses/${businessId}/messages/count`)) as {
      count: number;
    };

    return response.count;
  },
});

export const messagesCountQuery = atomFamily<number, string>({
  key: 'messagesCount',
  default: asyncDefaultMessagesCount,
});

const asyncDefaultEstimatedNumberOfRecipients = selectorFamily<
  number,
  { businessId: string; recipient: Recipient }
>({
  key: 'estimatedNumberOfRecipientsCount/default',
  get: (args) => async () => {
    const { businessId, recipient } = args;

    const response = (await post(
      `/api/businesses/${businessId}/messages/estimate_number_of_recipients`,
      {
        body: { recipient },
      },
    )) as { count: number };

    return response.count;
  },
});

export const estimatedNumberOfRecipientsQuery = atomFamily<
  number,
  { businessId: string; recipient: Recipient }
>({
  key: 'estimatedNumberOfRecipientsCount',
  default: asyncDefaultEstimatedNumberOfRecipients,
});

const { itemsQuery: accountsQuery, itemQuery: accountQuery } = createState<
  Account,
  { search: string } | undefined | string
>('account', {
  getAction({ args, get }) {
    const businessId = get(maybeCurrentBusinessIdState);

    const baseAction = `/api/${businessId ? `businesses/${businessId}/` : ''}accounts`;

    if (args instanceof Object) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { accountsQuery, accountQuery };
export const accountsState = accountsQuery(undefined);

const { itemsQuery: accountTransactionsQuery, itemQuery: accountTransactionQuery } = createState<
  Transaction,
  string | { accountId: string; id: string }
>('accountTransaction', {
  getAction({ args }) {
    const accountId = args instanceof Object ? args.accountId : args;

    return `/api/accounts/${accountId}/transactions`;
  },
});
export { accountTransactionsQuery, accountTransactionQuery };

const { itemsQuery: businessesQuery, itemQuery: businessQuery } = createState<
  Business,
  { search: string } | undefined | string
>('business', {
  getAction({ args }) {
    const baseAction = '/api/businesses';

    if (args instanceof Object) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { businessesQuery, businessQuery };
export const businessesState = businessesQuery(undefined);

const { itemsQuery: businessAccountsQuery, itemQuery: businessAccountQuery } = createState<
  Account,
  string | { businessId: string; id: string }
>('businessAccount', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    return `/api/businesses/${businessId}/accounts`;
  },
});
export { businessAccountsQuery, businessAccountQuery };

const { itemsQuery: businessCardsQuery, itemQuery: businessCardQuery } = createState<
  Card,
  string | { businessId: string; id: string }
>('businessCard', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    return `/api/businesses/${businessId}/cards`;
  },
});
export { businessCardsQuery, businessCardQuery };

const { itemsQuery: businessDevicesQuery, itemQuery: businessDeviceQuery } = createState<
  Device,
  string | { businessId: string; id: string }
>('businessDevice', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    return `/api/businesses/${businessId}/devices`;
  },
});
export { businessDevicesQuery, businessDeviceQuery };

const { itemsQuery: businessPlacesQuery, itemQuery: businessPlaceQuery } = createState<
  Place,
  string | { businessId: string; id: string }
>('businessPlace', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    return `/api/businesses/${businessId}/places`;
  },
});
export { businessPlacesQuery, businessPlaceQuery };

const { itemsQuery: businessProductsQuery, itemQuery: businessProductQuery } = createState<
  Product,
  string | { businessId: string; id: string }
>('businessProduct', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    return `/api/businesses/${businessId}/products`;
  },
});
export { businessProductsQuery, businessProductQuery };

const { itemsQuery: businessTransactionsQuery, itemQuery: businessTransactionQuery } = createState<
  Transaction,
  string | { businessId: string; id: string }
>('businessTransaction', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    return `/api/businesses/${businessId}/transactions`;
  },
});
export { businessTransactionsQuery, businessTransactionQuery };

const { itemsState: cardsState, itemQuery: cardQuery } = createSimpleState<Card>('card');
export { cardsState, cardQuery };

export const cardState = selector<Card | null>({
  key: 'cardState',
  get: ({ get }) => {
    const cards = get(cardsState);

    if (cards.length === 1) {
      return (cards as [Card])[0];
    }

    return null;
  },
});

const { itemsState: cardSubscriptionsState, itemQuery: cardSubscriptionQuery } =
  createSimpleState<CardSubscription>('card_subscription');
export { cardSubscriptionsState, cardSubscriptionQuery };

const { itemsQuery: cardSubscriptionCouponsQuery, itemQuery: cardSubscriptionCouponQuery } =
  createState<Coupon, string | { cardSubscriptionId: string; id: string }>(
    'cardSubscriptionCoupon',
    {
      getAction({ args }) {
        const cardSubscriptionId = args instanceof Object ? args.cardSubscriptionId : args;

        return `/api/card_subscriptions/${cardSubscriptionId}/coupons`;
      },
    },
  );
export { cardSubscriptionCouponsQuery, cardSubscriptionCouponQuery };

const { itemsQuery: cardSubscriptionFeedbackQuery, itemQuery: cardSubscriptionFeedbackItemQuery } =
  createState<Feedback, string | { cardSubscriptionId: string; id: string }>(
    'cardSubscriptionFeedback',
    {
      getAction({ args }) {
        const cardSubscriptionId = args instanceof Object ? args.cardSubscriptionId : args;

        return `/api/card_subscriptions/${cardSubscriptionId}/feedback`;
      },
    },
  );
export { cardSubscriptionFeedbackQuery, cardSubscriptionFeedbackItemQuery };

const {
  itemsQuery: cardSubscriptionTransactionsQuery,
  itemQuery: cardSubscriptionTransactionQuery,
} = createState<Transaction, string | { cardSubscriptionId: string; id: string }>(
  'cardSubscriptionTransaction',
  {
    getAction({ args }) {
      const cardSubscriptionId = args instanceof Object ? args.cardSubscriptionId : args;

      return `/api/card_subscriptions/${cardSubscriptionId}/transactions`;
    },
  },
);
export { cardSubscriptionTransactionsQuery, cardSubscriptionTransactionQuery };

const { itemsQuery: cardCardSubscriptionsQuery, itemQuery: cardCardSubscriptionQuery } =
  createState<
    CardSubscription,
    | string
    | { cardId: string; offset?: number; limit?: number; search?: string }
    | { cardId: string; id: string }
  >('cardCardSubscription', {
    getAction({ args }) {
      const cardId = args instanceof Object ? args.cardId : args;

      const baseAction = `/api/cards/${cardId}/card_subscriptions`;

      const searchParams = new URLSearchParams();

      if (args instanceof Object) {
        if ('offset' in args) {
          searchParams.set('offset', `${args.offset}`);
        }

        if ('limit' in args) {
          searchParams.set('limit', `${args.limit}`);
        }

        if ('search' in args && args.search) {
          searchParams.set('search', args.search);
        }

        return `${baseAction}?${searchParams.toString()}`;
      }

      return baseAction;
    },
  });
export { cardCardSubscriptionsQuery, cardCardSubscriptionQuery };

const { itemsQuery: challengesQuery, itemQuery: challengeQuery } = createState<
  Challenge,
  string | { cardId: string; id: string }
>('challenge', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/challenges`;
  },
});
export { challengesQuery, challengeQuery };

const { itemsQuery: couponsQuery, itemQuery: couponQuery } = createState<
  CouponWithStats,
  string | { cardId: string; search: string } | { cardId: string; id: string }
>('coupon', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    const baseAction = `/api/cards/${cardId}/coupons`;

    if (args instanceof Object && 'search' in args) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { couponsQuery, couponQuery };

const { itemsState: devicesState, itemQuery: deviceQuery } = createSimpleState<Device>('device');
export { devicesState, deviceQuery };

const { itemsQuery: deviceTransactionsQuery, itemQuery: deviceTransactionQuery } = createState<
  Transaction,
  string | { deviceId: string; id: string }
>('deviceTransaction', {
  getAction({ args }) {
    const deviceId = args instanceof Object ? args.deviceId : args;

    return `/api/devices/${deviceId}/transactions`;
  },
});
export { deviceTransactionsQuery, deviceTransactionQuery };

const { itemsQuery: feedbackQuery, itemQuery: feedbackItemQuery } = createState<
  Feedback,
  string | { cardId: string; id: string }
>('feedback', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/feedback`;
  },
});
export { feedbackQuery, feedbackItemQuery };

const { itemsState: integrationsState, itemQuery: integrationQuery } =
  createSimpleState<Integration>('integration');
export { integrationsState, integrationQuery };

export const integrationBusinessQuery = selectorFamily<IntegrationBusiness | null, string>({
  key: 'integrationBusiness',
  get:
    (integrationId) =>
    ({ get }) => {
      const businessId = get(maybeCurrentBusinessIdState);

      const action = `/api/${
        businessId ? `businesses/${businessId}/` : ''
      }integrations/${integrationId}/business`;

      return networkGet(action) as Promise<IntegrationBusiness | null>;
    },
});

const defaultIntegrationRecentOrdersState = selectorFamily<
  IntegrationOrder[] | null,
  string | null | undefined
>({
  key: 'integrationRecentOrders/default',
  get:
    (integrationId) =>
    ({ get }) => {
      if (!integrationId) {
        return null;
      }

      const businessId = get(maybeCurrentBusinessIdState);

      const action = `/api/${
        businessId ? `businesses/${businessId}/` : ''
      }integrations/${integrationId}/recent_orders`;

      return networkGet(action) as Promise<IntegrationOrder[]>;
    },
});

export const integrationRecentOrdersQuery = atomFamily<
  IntegrationOrder[] | null,
  string | null | undefined
>({
  key: 'integrationRecentOrders',
  default: defaultIntegrationRecentOrdersState,
});

export const integrationOrderQuery = selectorFamily<
  IntegrationOrder[] | null,
  { integrationId: string; orderId: string }
>({
  key: 'integrationOrder',
  get:
    ({ integrationId, orderId }) =>
    ({ get }) => {
      if (!integrationId || !orderId) {
        return null;
      }

      const businessId = get(maybeCurrentBusinessIdState);

      const action = `/api/${
        businessId ? `businesses/${businessId}/` : ''
      }integrations/${integrationId}/orders/${orderId}`;

      return networkGet(action) as Promise<IntegrationOrder[]>;
    },
});

const { itemsQuery: integrationProductsQuery, itemQuery: integrationProductQuery } = createState<
  { _id: string; name: string },
  string | { integrationId: string; id: string }
>('integrationProduct', {
  getAction({ args, get }) {
    const businessId = get(maybeCurrentBusinessIdState);

    const integrationId = args instanceof Object ? args.integrationId : args;

    return `/api/${
      businessId ? `businesses/${businessId}/` : ''
    }integrations/${integrationId}/products`;
  },
});

export { integrationProductsQuery, integrationProductQuery };

const { itemsQuery: levelsQuery, itemQuery: levelQuery } = createState<
  Level,
  string | { cardId: string; id: string }
>('level', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/levels`;
  },
});
export { levelsQuery, levelQuery };

const { itemsQuery: messagesQuery, itemQuery: messageQuery } = createState<
  Message,
  string | { businessId: string; search: string } | { businessId: string; id: string }
>('message', {
  getAction({ args }) {
    const businessId = args instanceof Object ? args.businessId : args;

    const baseAction = `/api/businesses/${businessId}/messages`;

    if (args instanceof Object && 'search' in args) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { messagesQuery, messageQuery };

const { itemsQuery: offersQuery, itemQuery: offerQuery } = createState<
  Offer,
  string | { cardId: string; id: string }
>('offer', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/offers`;
  },
});
export { offersQuery, offerQuery };

const { itemsQuery: placesQuery, itemQuery: placeQuery } = createState<
  Place,
  { search: string } | undefined | string
>('place', {
  getAction({ args, get }) {
    const businessId = get(maybeCurrentBusinessIdState);

    const baseAction = `/api/${businessId ? `businesses/${businessId}/` : ''}places`;

    if (args instanceof Object) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { placesQuery, placeQuery };
export const placesState = placesQuery(undefined);

const { itemsState: plansState, itemQuery: planQuery } = createSimpleState<Plan>('plan', {
  scopedRoute: false,
});
export { plansState, planQuery };

const { itemsQuery: productsQuery, itemQuery: productQuery } = createState<
  Product,
  { search: string } | undefined | string
>('product', {
  getAction({ args, get }) {
    const businessId = get(maybeCurrentBusinessIdState);

    const baseAction = `/api/${businessId ? `businesses/${businessId}/` : ''}products`;

    if (args instanceof Object) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { productsQuery, productQuery };
export const productsState = productsQuery(undefined);

export const placeProductsQuery = selectorFamily<Product[], string>({
  key: 'placeProducts',
  get:
    (placeId) =>
    ({ get }) => {
      const products = get(productsState);

      return products.filter((product) => product.place_ids?.includes(placeId));
    },
  set:
    () =>
    ({ set }, newValue) => {
      set(productsState, (products) =>
        newValue instanceof DefaultValue
          ? newValue
          : products.map((product) => {
              const nextProduct = newValue.find((nextProduct) => nextProduct._id === product._id);

              return nextProduct || product;
            }),
      );
    },
});

export const placeProductQuery = selectorFamily<Product, { placeId: string; id: string }>({
  key: 'placeProduct',
  get:
    (args) =>
    ({ get }) => {
      const productId = args instanceof Object ? args.id : args;

      return get(productQuery(productId));
    },
  set:
    (args) =>
    ({ set }, newValue) => {
      const productId = args instanceof Object ? args.id : args;

      set(productQuery(productId), newValue);
    },
});

const { itemsQuery: promotionGroupsQuery, itemQuery: promotionGroupQuery } = createState<
  PromotionGroup,
  string | { cardId: string; id: string }
>('promotionGroup', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    return `/api/cards/${cardId}/promotion_groups`;
  },
});
export { promotionGroupsQuery, promotionGroupQuery };

const { itemsQuery: rewardsQuery, itemQuery: rewardQuery } = createState<
  Reward,
  string | { cardId: string; search: string } | { cardId: string; id: string }
>('reward', {
  getAction({ args }) {
    const cardId = args instanceof Object ? args.cardId : args;

    const baseAction = `/api/cards/${cardId}/rewards`;

    if (args instanceof Object && 'search' in args) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { rewardsQuery, rewardQuery };

const defaultCurrentPlanState = selector<Plan | null>({
  key: 'currentPlanState/default',
  get({ get }) {
    const currentPlanSubscription = get(currentPlanSubscriptionState);

    if (!currentPlanSubscription) {
      return null;
    }

    const currentPlan = get(planQuery(currentPlanSubscription.plan_id));

    return currentPlan;
  },
});

export const currentPlanState = atom<Plan | null>({
  key: 'currentPlanState',
  default: defaultCurrentPlanState,
});

export const preferredIntegrationIdState = atom<string | null | undefined>({
  key: 'preferredIntegrationIdState',
  default: undefined,
  effects: [localStorageEffect('preferredIntegrationId')],
});

export const currentIntegrationIdState = selector<string | null | undefined>({
  key: 'currentIntegrationIdState',
  get: ({ get }) => {
    const integrations = get(integrationsState);

    // If there's no integrations, return undefined
    if (!integrations.length) {
      return undefined;
    }

    if (!ALLOW_NULL_INTEGRATION) {
      // If there's one integration, return its id regardless of the preferredIntegrationId
      if (integrations.length === 1) {
        return (integrations as [Integration])[0]._id;
      }
    }

    // If there's more than one integration, return the preferredIntegrationId, provided that the
    // integration with that id exists or id is null. If not, return null/undefined.
    const preferredIntegrationId = get(preferredIntegrationIdState);

    if (!preferredIntegrationId) {
      return ALLOW_NULL_INTEGRATION ? preferredIntegrationId : undefined;
    }

    const preferredIntegration = integrations.find(
      (integration) => integration._id === preferredIntegrationId,
    );

    if (!preferredIntegration) {
      return undefined;
    }

    return preferredIntegrationId;
  },
});

export const statsSummaryQuery = selectorFamily<
  StatsSummary,
  {
    from?: string;
    to?: string;
  }
>({
  key: 'statsSummary',
  get:
    (args) =>
    ({ get }) => {
      const { from, to } = args;
      const businessId = get(maybeCurrentBusinessIdState);

      const action = `/api/${businessId ? `businesses/${businessId}/` : ''}stats/summary`;

      if (from || to) {
        const params: {
          from?: string;
          to?: string;
        } = {};
        if (from) {
          params.from = from;
        }
        if (to) {
          params.to = to;
        }
        return networkGet(`${action}${stringify(params)}`) as Promise<StatsSummary>;
      }

      return networkGet(action) as Promise<StatsSummary>;
    },
});

const now = new Date();

const min = new Date(2020, 0, 1);
const max = startOfDay(now);

export const statsQuery = selectorFamily<
  Stats | null,
  {
    from?: string;
    style: StatStyle;
    to?: string;
    type: StatType;
  }
>({
  key: 'stats',
  get:
    (args) =>
    ({ get }) => {
      const {
        /* users, transactions, rewards */
        type,
        /* new, total */
        style,
        from,
        to,
      } = args;
      const businessId = get(maybeCurrentBusinessIdState);

      const action = `/api/${businessId ? `businesses/${businessId}/` : ''}stats/${type}/${style}`;

      if (from || to) {
        const params: Record<string, string> = {};
        if (from) {
          const fromWithTime = `${from}T00:00`;
          if (new Date(fromWithTime) < min) {
            return null;
          }
          params.from = from;
        }
        if (to) {
          const toWithtime = `${to}T00:00`;
          if (new Date(toWithtime) > max) {
            return null;
          }
          params.to = to;
        }
        return networkGet(`${action}${stringify(params)}`) as Promise<Stats>;
      }

      return networkGet(action) as Promise<Stats>;
    },
});

export const suggestRewardsQuery = selectorFamily<
  { name: string; description: string }[],
  {
    name: string;
    category: string;
    description?: string;
  }
>({
  key: 'suggestRewards',
  get:
    ({ name, category, description }) =>
    () => {
      const params: Record<string, string> = { name, category };
      if (description) {
        params.description = description;
      }
      const action = `/api/ai/suggest_rewards${stringify(params)}`;

      return networkGet(action) as Promise<{ name: string; description: string }[]>;
    },
});

const { itemsState: transactionsState, itemQuery: transactionQuery } =
  createSimpleState<Transaction>('transaction');
export { transactionsState, transactionQuery };

const { itemsQuery: usersQuery, itemQuery: userQuery } = createState<
  User,
  { search: string } | undefined | string
>('user', {
  getAction({ args, get }) {
    const businessId = get(maybeCurrentBusinessIdState);

    const baseAction = `/api/${businessId ? `businesses/${businessId}/` : ''}users`;

    if (args instanceof Object) {
      return `${baseAction}?search=${args.search}`;
    }

    return baseAction;
  },
});
export { usersQuery, userQuery };
export const usersState = usersQuery(undefined);

const { itemsQuery: userCardSubscriptionsQuery, itemQuery: userCardSubscriptionQuery } =
  createState<CardSubscription, string | { userId: string; id: string }>('cardSubscription', {
    getAction({ args }) {
      const userId = args instanceof Object ? args.userId : args;

      return `/api/users/${userId}/card_subscriptions`;
    },
  });
export { userCardSubscriptionsQuery, userCardSubscriptionQuery };

export const pendingIntegrationRecentOrdersRefreshesState = atom<string[]>({
  key: 'pendingIntegrationRecentOrdersRefreshesState',
  default: [],
});
