import styled from 'styled-components';

import { header } from '@rewardopl/styles/z-index';

import { Wrapper as RewardoLogo } from '../../common/rewardo_logo.styles';

export const headerHeight = 60;

export const Wrapper = styled.header`
  flex-shrink: 0;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${headerHeight}px;
  padding: 10px var(--gap);
  padding-top: calc(10px + env(safe-area-inset-top));
  padding-right: max(var(--gap), env(safe-area-inset-right));
  background: var(--white);
  border-bottom: 1px solid #eee;
  z-index: ${header};
  gap: var(--gap);

  @supports (backdrop-filter: blur(10px)) {
    background: rgba(var(--white-rgb), 0.8);
    backdrop-filter: blur(10px);
  }

  @media screen and (min-width: 769px) {
    justify-content: flex-end;
    background: var(--white);
    border-bottom: none;

    ${RewardoLogo} {
      display: none;
    }
  }
`;
