import styled, { keyframes } from 'styled-components';

import {
  MenuTrigger,
  Button,
  Popover,
  Menu as ReactAriaMenu,
  MenuItem as ReactAriaMenuItem,
} from 'react-aria-components';

export { MenuTrigger, Button, Popover };

const spaceForShadow = 24;

const unroll = keyframes`
  0% {
  clip-path: polygon(
    -${spaceForShadow}px -${spaceForShadow}px,
    calc(100% + ${spaceForShadow}px) -${spaceForShadow}px,
    calc(100% + ${spaceForShadow}px) 0,
    -${spaceForShadow}px 0
  );
  }

  100% {
    clip-path: polygon(
      -${spaceForShadow}px -${spaceForShadow}px,
      calc(100% + ${spaceForShadow}px) -${spaceForShadow}px,
      calc(100% + ${spaceForShadow}px) calc(100% + ${spaceForShadow}px),
      -${spaceForShadow}px calc(100% + ${spaceForShadow}px)
    );
  }
`;

export const Menu = styled(ReactAriaMenu)`
  display: block;
  white-space: nowrap;
  padding: 0;
  background: white;
  border: 0;
  border-radius: 6px;
  box-shadow: var(--shadow-2);
  font: inherit;
  outline: none;
  animation: ${unroll} 250ms ease-in-out;
`;

export const MenuItem = styled(ReactAriaMenuItem)`
  display: block;
  padding: 13px 24px;
  color: var(--black);
  cursor: pointer;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }

  &:hover,
  &:focus {
    background: rgba(var(--black-rgb), 0.05);
    color: var(--black);
    text-decoration: none;
  }
`;
