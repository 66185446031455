import styled, { css, createGlobalStyle } from 'styled-components';

import { PageWrapper } from '../index.styles';

export const GlobalStyle = createGlobalStyle`${css`
  ${PageWrapper} {
    background: var(--gray-light);
  }
`}`;

export const Section = styled.section`
  margin: 0 0 var(--gap) 0;
  text-align: left;
`;
