import styled from 'styled-components';

import { Button as CommonButton } from '@rewardopl/react-ui/button/index.styles';

export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  margin: calc((var(--gap) * 2) - 24px) -24px 0;
  padding: 24px;
  background: var(--white);

  @media screen and (min-width: 960px) {
    position: static;
    margin: calc((var(--gap) * 2)) 0;
    padding: 0;
    background: none;
  }
`;

export const Button = styled(CommonButton)`
  &.primary {
    width: 100%;
    padding: 18px 24px;
    font-size: 16px;

    @media screen and (min-width: 960px) {
      width: auto;
    }
  }
`;

export const Sub = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
`;
