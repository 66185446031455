import { useRecoilValue } from 'recoil';

import { isRewardoAdmin } from '../helpers/authorization';

import { cardState, placesState, currentAccountState } from '../recoil';

export default function useIsConfigured(): boolean {
  const currentAccount = useRecoilValue(currentAccountState);
  const places = useRecoilValue(placesState);
  const card = useRecoilValue(cardState);

  const hasPlace = Boolean(places.length);
  const hasCard = Boolean(card);

  return isRewardoAdmin(currentAccount) || (hasPlace && hasCard);
}
