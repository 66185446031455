import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { Wrapper } from './plan_subscription_issues_banner.styles';

import { isAdmin } from '../../../helpers/authorization';

import { currentAccountState, currentPlanSubscriptionState } from '../../../recoil';

function PlanSubscriptionIssuesBannerContent() {
  const currentPlanSubscription = useRecoilValue(currentPlanSubscriptionState);
  const currentAccount = useRecoilValue(currentAccountState);

  if (!currentPlanSubscription || !currentPlanSubscription.has_issues) {
    return null;
  }

  const shouldDisplayAdminMessage = isAdmin(currentAccount);

  return (
    <Wrapper>
      {shouldDisplayAdminMessage ? (
        <>
          <p>
            <T>
              We’ve identified an issue with your subscription that may affect the functionality of
              your account.
            </T>{' '}
            <T>Please contact our support team for quick assistance.</T>
          </p>
          <Link to="#contact-us">
            <T>Contact us</T>
          </Link>
        </>
      ) : (
        <p>
          <T>
            We’ve identified an issue with your subscription that may affect the functionality of
            your account.
          </T>{' '}
          <T>Please contact your administrator.</T>
        </p>
      )}
    </Wrapper>
  );
}

export default function PlanSubscriptionIssuesBanner() {
  return (
    <Suspense fallback={null}>
      <PlanSubscriptionIssuesBannerContent />
    </Suspense>
  );
}
