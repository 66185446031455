import { Wrapper } from './header.styles';

import RewardoLogo from './common/rewardo_logo';
import LogoutButton from './logout_button';

export default function Header() {
  return (
    <Wrapper>
      <RewardoLogo />
      <LogoutButton />
    </Wrapper>
  );
}
