import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import T from '@wojtekmaj/react-t';

import { Wrapper, Label, Value } from './index.styles';

import Fieldset from '../fieldset';
import AddressField from '../address_field';
import RadioFields from './radio_fields';

import { formatAddress } from '../../../utils/helpers';

import { maybeCurrentBusinessState } from '../../../recoil';

import type { Address } from '@rewardopl/types/helpers/address';

type AddressFieldWithDefaultProps = React.ComponentPropsWithoutRef<typeof AddressField>;

export default function AddressFieldWithDefault(props: AddressFieldWithDefaultProps) {
  const { label, name, onChange, required = true, value } = props;

  const currentBusiness = useRecoilValue(maybeCurrentBusinessState);

  const isBusinessAndHasBillingAddress = currentBusiness?.billing_address;
  const isDefaultValue =
    isBusinessAndHasBillingAddress &&
    (!value || JSON.stringify(value) === JSON.stringify(currentBusiness.billing_address));
  const [mode, setMode] = useState(isDefaultValue ? 'default' : 'custom');
  const [customAddress, setCustomAddress] = useState<Partial<Address> | undefined>(value);

  function onChangeMode(nextMode: string) {
    setMode(nextMode);

    onChange(
      nextMode === 'default' && currentBusiness?.billing_address
        ? currentBusiness.billing_address
        : customAddress,
    );
  }

  function onChangeCustomAddress(nextCustomAddress: Partial<Address> | undefined) {
    setCustomAddress(nextCustomAddress);

    if (mode === 'custom') {
      onChange(nextCustomAddress);
    }
  }

  if (!isBusinessAndHasBillingAddress) {
    return <AddressField {...props} />;
  }

  const modeOptions = [
    {
      label: (
        <>
          <Label>
            <T>Business address</T>
          </Label>
          <Value>{formatAddress(currentBusiness.billing_address)}</Value>
        </>
      ),
      value: 'default',
    },
    {
      label: (
        <Label>
          <T>Other address</T>
        </Label>
      ),
      value: 'custom',
    },
  ];

  return (
    <Fieldset label={label} required={required}>
      <Wrapper>
        <RadioFields
          name={`${name}[mode]`}
          form="_private"
          label="Address type"
          options={modeOptions}
          onChange={onChangeMode}
          required={required}
          value={mode}
        />
        {mode === 'default' && (
          <>
            <input
              type="hidden"
              name={`${name}[address]`}
              value={currentBusiness.billing_address?.address}
            />
            <input
              type="hidden"
              name={`${name}[postcode]`}
              value={currentBusiness.billing_address?.postcode}
            />
            <input
              type="hidden"
              name={`${name}[city]`}
              value={currentBusiness.billing_address?.city}
            />
            <input
              type="hidden"
              name={`${name}[country]`}
              value={currentBusiness.billing_address?.country}
            />
          </>
        )}
        {mode === 'custom' && (
          <AddressField
            label="Other address"
            name={name}
            onChange={onChangeCustomAddress}
            value={customAddress}
          />
        )}
      </Wrapper>
    </Fieldset>
  );
}
