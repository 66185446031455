import { lazy, Suspense } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import Dialog from '@rewardopl/react-ui/dialog';

import LoadingText from './loading_text';

const ServiceContract = lazy(() => import('./service_contract'));

export default function ServiceContractLink() {
  const location = useLocation();
  const navigate = useNavigate();

  const close = () => {
    navigate(
      {
        ...location,
        hash: '',
      },
      { state: location.state },
    );
  };

  return (
    <>
      <Link to="#service-contract">
        <T>Service contract</T>
      </Link>
      <Dialog
        isOpen={location.hash === '#service-contract'}
        title="Service contract"
        onDismiss={close}
      >
        <Suspense fallback={<LoadingText />}>
          <ServiceContract />
        </Suspense>
      </Dialog>
    </>
  );
}
