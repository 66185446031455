import styled from 'styled-components';

import { Wrapper as FieldWrapper } from '@rewardopl/react-ui/field/index.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-height: 600px) {
    overflow: auto;
  }
`;

export const Form = styled.form`
  max-width: unset !important;
  display: flex;
  flex-direction: column;

  @media screen and (min-height: 600px) {
    overflow: auto;
  }
`;

export const List = styled.div`
  height: 200px;
  margin: var(--gap) 0;
  overflow: auto;

  @media screen and (min-height: 600px) {
    height: auto;
  }

  ${FieldWrapper} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: var(--gap);
`;
