import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'header' 'main' 'footer';
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  position: fixed;
  inset: 0;

  @media (screen-spanning: single-fold-vertical) {
    width: env(fold-left);
  }

  @media (horizontal-viewport-segments: 2) {
    width: env(fold-left);
  }
`;

export const ContentWrapper = styled.div<{ wider?: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.wider ? 1152 : 960)}px;
  margin: 0 auto;
  padding: var(--gap);

  h1 {
    margin-top: var(--gap);
    margin-bottom: calc(var(--gap) * 2);
    text-align: center;

    @media screen and (min-width: 960px) {
      text-align: left;
    }
  }

  h2 {
    font-size: 16px;
  }
`;

export const Main = styled.main``;
