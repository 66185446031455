import { Link, useLocation } from 'react-router-dom';
import T from '@wojtekmaj/react-t';

import { PageWrapper, Main, FormWrapper } from './index.styles';

import Heading from '../../heading';
import Header from '../../header';
import LoginForm from './form';
import Footer from '../../footer';

export default function LoginPage() {
  const location = useLocation();

  return (
    <PageWrapper>
      <Header />
      <FormWrapper>
        <Main>
          <Heading align="center">Login</Heading>
          <LoginForm />
          <p>
            <T>Don’t have an account yet?</T>{' '}
            <Link to="/register" state={location.state}>
              <T>Register</T>
            </Link>
            .
          </p>
        </Main>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
}
