import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;

  h2 {
    margin-bottom: 16px;
    text-align: center;

    @media screen and (min-width: 960px) {
      text-align: left;
    }
  }
`;
