import { useRecoilValue } from 'recoil';
import { Navigate, Routes, Route } from 'react-router-dom';

import { PageWrapper, Main, ContentWrapper } from './index.styles';

import Header from '../../header';
import Footer from '../../footer';

import OnboardingPlan from './plan';
import OnboardingDetails from './details';

import { maybeCurrentBusinessState } from '../../../recoil';

export default function OnboardingPage() {
  const currentBusiness = useRecoilValue(maybeCurrentBusinessState);

  const hasPlan = !currentBusiness || Boolean(currentBusiness.plan_id);

  if (hasPlan) {
    return <Navigate to="/" />;
  }

  return (
    <PageWrapper>
      <Header />
      <Routes>
        <Route
          path="plan"
          element={
            <ContentWrapper wider>
              <Main>
                <OnboardingPlan />
              </Main>
            </ContentWrapper>
          }
        />
        <Route
          path="details"
          element={
            <ContentWrapper>
              <Main>
                <OnboardingDetails />
              </Main>
            </ContentWrapper>
          }
        />
        <Route path="*" element={<Navigate to="plan" />} />
      </Routes>
      <Footer />
    </PageWrapper>
  );
}
