import T from '@wojtekmaj/react-t';

import { Section } from './index.styles';

import renderFields from '../../../form/render_fields';

import type { Account } from '@rewardopl/types';

type UserDetailsFormProps = {
  formData: Partial<Account>;
  setFormData: (formData: Partial<Account>) => void;
};

export default function UserDetailsForm({ formData, setFormData }: UserDetailsFormProps) {
  return (
    <Section>
      <h2>
        <T>User details</T>
      </h2>
      {renderFields({
        fields: [{ name: 'first_name' }, { name: 'last_name' }],
        formData,
        setFormData,
      })}
    </Section>
  );
}
