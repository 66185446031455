import { useRecoilValue } from 'recoil';
import T from '@wojtekmaj/react-t';

import {
  Amount,
  InputWrapper,
  Label,
  Icon,
  Name,
  Places,
  Price,
  RegularPriceAmount,
  CurrentPriceAmount,
  NeedMore,
} from './plan_buttons.styles';

import ContactUsLink from '../../../contact_us_link';

import { formatPrice } from '../../../../utils/helpers';

import { plansState } from '../../../../recoil';

import checkIcon from '../../../../static/check.svg';

import type { Plan } from '@rewardopl/types';

const sortByMaxPlaces = (a: Plan, b: Plan) => (a.max_places || 0) - (b.max_places || 0);

function shouldUsePromoPrice(plan: Plan): plan is Plan & { promo_price: number } {
  return Boolean(
    plan.promo_price && plan.promo_valid_to && new Date(plan.promo_valid_to) >= new Date(),
  );
}

function getPrice(plan: Plan): number {
  if (shouldUsePromoPrice(plan)) {
    return plan.promo_price;
  }

  return plan.price;
}

type PlanButtonsProps = {
  name: string;
  onChange: (planId: string) => void;
  value: string;
};

export default function PlanButtons({ name, onChange, value }: PlanButtonsProps) {
  const plans = useRecoilValue(plansState);

  const plansSortedByMaxPlaces = [...plans].sort(sortByMaxPlaces);

  const lastPlan = plansSortedByMaxPlaces.at(-1);

  if (!lastPlan) {
    throw new Error('No plans found');
  }

  return (
    <InputWrapper>
      {plans.map((plan) => {
        const checked = value === plan._id;

        return (
          <Label key={plan._id} checked={checked}>
            <Icon>{checked ? <img width={22} height={22} src={checkIcon} alt="" /> : null}</Icon>
            <input
              checked={checked}
              name={name}
              onChange={() => onChange(plan._id)}
              required
              type="radio"
              value={plan._id}
            />
            <Name>{plan.name}</Name>
            <Places>
              <Amount>{plan.max_places}</Amount> <T>{plan.max_places !== 1 ? 'places' : 'place'}</T>
            </Places>
            <Price>
              <CurrentPriceAmount>{formatPrice(getPrice(plan))}</CurrentPriceAmount>
              {shouldUsePromoPrice(plan) && (
                <RegularPriceAmount>{formatPrice(plan.price)}</RegularPriceAmount>
              )}
              <T>monthly</T>
            </Price>
          </Label>
        );
      })}
      <NeedMore>
        <p>
          <T amount={lastPlan.max_places}>
            {'Have more than {amount} places or looking for a dedicated app?'}
          </T>
        </p>
        <p>
          <ContactUsLink />
        </p>
      </NeedMore>
    </InputWrapper>
  );
}
