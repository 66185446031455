import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';

import SelectIntegrationDialog from './dialog';

import { isRewardoAdmin } from '../../../../helpers/authorization';

import {
  currentAccountState,
  currentIntegrationIdState,
  integrationsState,
} from '../../../../recoil';

import { ALLOW_NULL_INTEGRATION } from '../../../../constants';

export default function SelectIntegration() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentAccount = useRecoilValue(currentAccountState);
  const integrations = useRecoilValue(integrationsState);
  const currentIntegrationId = useRecoilValue(currentIntegrationIdState);

  const hasAtLeastOneIntegration = !isRewardoAdmin(currentAccount) && integrations.length >= 1;
  const hasMultipleIntegrations = !isRewardoAdmin(currentAccount) && integrations.length > 1;

  const mustDisplayDialog =
    (ALLOW_NULL_INTEGRATION ? hasAtLeastOneIntegration : hasMultipleIntegrations) &&
    currentIntegrationId === undefined;

  const close = () => {
    navigate(
      {
        ...location,
        hash: '',
      },
      { state: location.state },
    );
  };

  return (
    <SelectIntegrationDialog
      onDismiss={mustDisplayDialog ? undefined : close}
      mustDisplayDialog={mustDisplayDialog}
    />
  );
}
