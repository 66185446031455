import { useCurrentPosition } from '@wojtekmaj/react-hooks';
import { Map as GoogleMap, Marker } from '@rewardopl/react-maps';

import { getGMapsPositionFromApi, getGMapsPositionFromGeolocation } from '@rewardopl/utils/geo';
import { round } from '@rewardopl/utils/number';

import { GOOGLE_MAPS_API_KEY } from '../../../constants';

import marker from '../../../static/marker.svg';
import pinActive from '../../../static/pin-active.svg';

const containerStyle = {
  width: '100%',
  maxWidth: '640px',
  height: '240px',
};

const defaultCenter = {
  lat: 51.919438,
  lng: 19.145136,
};

const mapOptions = {
  clickableIcons: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: true,
};

const markerOptions = {
  gmpDraggable: true,
};

function roundPosition(num: number): number {
  return round(num, 6);
}

type MapComponentProps = {
  onChange: (value: [number, number]) => void;
  value?: [number, number];
};

export default function MapComponent({ onChange, value }: MapComponentProps) {
  const rawPosition = useCurrentPosition();
  const position = getGMapsPositionFromGeolocation(rawPosition);

  const defaultZoom = value ? 14 : 4;
  const valueWithDefault = getGMapsPositionFromApi(value) || defaultCenter;

  return (
    <GoogleMap
      apiKey={GOOGLE_MAPS_API_KEY}
      defaultCenter={valueWithDefault}
      defaultZoom={defaultZoom}
      mapContainerStyle={containerStyle}
      mapOptions={mapOptions}
    >
      {position ? <Marker key="__position" position={position} icon={marker} /> : null}
      <Marker
        markerOptions={markerOptions}
        onDragEnd={({ latLng }) => {
          if (!latLng) {
            return;
          }

          onChange([roundPosition(latLng.lng()), roundPosition(latLng.lat())]);
        }}
        position={valueWithDefault}
        icon={pinActive}
      />
    </GoogleMap>
  );
}
