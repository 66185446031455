import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import { formatISO, startOfMonth } from 'date-fns';
import T from '@wojtekmaj/react-t';

import {
  ArrowLeftRightIcon,
  BriefcaseIcon,
  ClipboardIcon,
  BadgePercentIcon,
  DollarSignIcon,
  HardDriveIcon,
  HomeIcon,
  MapPinIcon,
  UsersIcon,
  LineChartIcon,
} from 'lucide-react';

import { Wrapper, List, ListItem, ListItemLink, TabIndicator, Icon, Label } from './menu.styles';

import AuthListItemLink from './auth_list_item_link';

import { isAnyAdmin, isRewardoAdmin } from '../../../helpers/authorization';

import { cardState } from '../../../recoil';

const AnimatedTabIndicator = animated(TabIndicator);

function formatISODate(date: Date): string {
  return formatISO(date, { representation: 'date' });
}

const now = new Date();

const dateFrom = formatISODate(startOfMonth(now));
const dateTo = formatISODate(now);

const params = new URLSearchParams({
  date_from: dateFrom,
  date_to: dateTo,
});

export default function Menu() {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const card = useRecoilValue(cardState);
  const tabWrapper = useRef<HTMLUListElement>(null);
  const shouldUseImmediate = useRef(true);
  const [indicatorRect, setIndicatorRect] = useState<object>({ left: 0 });

  const props = useSpring(indicatorRect);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on window width change
  useEffect(() => {
    shouldUseImmediate.current = true;
  }, [windowWidth]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on location change
  useEffect(() => {
    if (!tabWrapper.current) {
      return;
    }

    const activeTab = tabWrapper.current.querySelector('.active');

    function getRect() {
      if (!tabWrapper.current) {
        return;
      }

      if (!activeTab) {
        setIndicatorRect({
          opacity: 0,
          immediate: true,
        });

        shouldUseImmediate.current = true;

        return;
      }

      const rect = activeTab.getBoundingClientRect();

      if (!rect.width && !rect.height) {
        requestAnimationFrame(getRect);
        return;
      }

      const parentRect = tabWrapper.current.getBoundingClientRect();

      const immediate = shouldUseImmediate.current;

      setIndicatorRect({
        left: rect.x - parentRect.x + rect.width / 2,
        opacity: 1,
        immediate,
      });

      shouldUseImmediate.current = false;
    }

    getRect();
  }, [location.pathname, windowWidth]);

  const items = [
    {
      label: 'Home',
      icon: HomeIcon,
      href: './',
      exact: true,
    },
    {
      label: 'Businesses',
      icon: BriefcaseIcon,
      href: 'businesses',
      anyOf: [isRewardoAdmin],
    },
    card
      ? {
          label: 'Loyalty program',
          icon: BadgePercentIcon,
          href: `cards/${card._id}`,
        }
      : {
          label: 'Loyalty programs',
          icon: BadgePercentIcon,
          href: 'cards',
        },
    {
      label: 'Transactions',
      icon: ArrowLeftRightIcon,
      href: 'transactions',
    },
    {
      label: 'Places',
      icon: MapPinIcon,
      href: 'places',
    },
    {
      label: 'Offer',
      icon: ClipboardIcon,
      href: 'products',
    },
    {
      label: 'Accounts',
      icon: UsersIcon,
      href: 'accounts',
    },
    {
      label: 'Devices',
      icon: HardDriveIcon,
      href: 'devices',
    },
    {
      label: 'Plans',
      icon: DollarSignIcon,
      href: 'plans',
      anyOf: [isRewardoAdmin],
    },
    {
      label: 'Users',
      icon: UsersIcon,
      href: 'users',
      anyOf: [isRewardoAdmin],
    },
    {
      label: 'Stats',
      icon: LineChartIcon,
      href: `stats?${params}`,
      anyOf: isAnyAdmin,
    },
  ];

  return (
    <Wrapper>
      <List ref={tabWrapper} role="list">
        {items.map((item) => {
          const IconComponent = item.icon;

          const content = (
            <>
              <Icon>
                <IconComponent />
              </Icon>
              <Label>
                <T>{item.label}</T>
              </Label>
            </>
          );

          const link = item.anyOf ? (
            <AuthListItemLink
              className={({ isActive }) => (isActive ? 'active' : '')}
              to={item.href}
              end={item.exact}
              anyOf={item.anyOf}
            >
              {content}
            </AuthListItemLink>
          ) : (
            <ListItemLink
              className={({ isActive }) => (isActive ? 'active' : '')}
              to={item.href}
              end={item.exact}
            >
              {content}
            </ListItemLink>
          );

          return <ListItem key={item.label}>{link}</ListItem>;
        })}
      </List>
      <AnimatedTabIndicator style={props} />
    </Wrapper>
  );
}
