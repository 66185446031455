import styled, { css } from 'styled-components';

import { visuallyHidden } from '@rewardopl/react-ui/visually_hidden/index.styles';

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
  gap: 16px;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
  }
`;

export const Label = styled.label<{ checked?: boolean }>`
  position: relative;
  width: 100%;
  min-height: 210px;
  padding: 16px 16px 24px;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-2);
  font: inherit;
  cursor: pointer;

  &:hover,
  &:focus,
  :focus-within {
    border-color: var(--primary);
  }

  ${(props) =>
    props.checked &&
    css`
      border-color: var(--primary);
    `}

  input {
    ${visuallyHidden}
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Name = styled.p`
  margin-top: 0;
  /* largest icon size + gap */
  padding-right: calc(24px + var(--gap));
  line-height: 22px;
`;

export const Places = styled.p`
  margin-bottom: 0;
  line-height: 1.2;
  text-align: center;
`;

export const Amount = styled.span`
  font-size: 32px;
  font-weight: 500;
`;

export const Price = styled.p`
  margin-bottom: 0;
  color: var(--text-light);
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
`;

const priceAmountStyles = css`
  display: block;
  color: var(--secondary-dark);
  font: inherit;
  font-weight: 500;
`;

export const RegularPriceAmount = styled.span`
  ${priceAmountStyles}
  margin-bottom: 6px;
  font-size: 16px;
  text-decoration: line-through;
`;

export const CurrentPriceAmount = styled.span`
  ${priceAmountStyles}
  margin-bottom: 4px;
  font-size: 32px;
`;

export const NeedMore = styled.div`
  width: 100%;
  min-height: 210px;
  padding: 16px 0 24px;

  p {
    margin-top: 0;
  }
`;
