import { ACCOUNT_ROLES } from '../constants';

import type { Account } from '@rewardopl/types';

export function isLoggedIn(user: Account | null): user is Account {
  return Boolean(user);
}

export function isLoggedOut(user: Account | null): user is null {
  return !isLoggedIn(user);
}

export function isRewardoAdmin(user: Account | null): boolean {
  return isLoggedIn(user) && user.role === ACCOUNT_ROLES.REWARDO_ADMIN;
}

export function isOwner(user: Account | null): boolean {
  return isLoggedIn(user) && user.role === ACCOUNT_ROLES.OWNER;
}

export function isAdmin(user: Account | null): boolean {
  return (isLoggedIn(user) && user.role === ACCOUNT_ROLES.ADMIN) || isOwner(user);
}

export function isEmployee(user: Account | null): boolean {
  return (isLoggedIn(user) && user.role === ACCOUNT_ROLES.EMPLOYEE) || isAdmin(user);
}

export const isAnyAdmin = [isRewardoAdmin, isAdmin];
